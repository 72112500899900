<template>
  <div class="j_lnb_oms_modules">
    <div class="lnb_radio">
      <span>Modules</span>
      <div class="option_group">
        <div v-for="(option, i) in moduleOptions" :key="i">
          <label :for="`j__lnb_oms_module_radio___${i}`">
            <input 
              v-model="MODULE"
              type="radio"
              :id="`j__lnb_oms_module_radio___${i}`"
              :name="option"
              :value="option"
            />
            {{moduleDesc.find(d => d.key === option).desc}}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Construction 3D Viewer Overall Module Status
import '@/assets/stylus/ui/component/_jLnbCMSLevelStruct.styl'

import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { DBSupportService } from "@/services"
import Loading from '@/mixins/loading.mixin'

export default {
  name: 'j-lnb-o-m-s-modules',
  mixins: [
    Loading
  ],
  data: () =>({
    queryLibService: null,

    moduleOptions: [],
    moduleDesc: []

  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'filteredValues'
    ]),

    MODULE: {
      get() { return this.filteredValues && this.filteredValues['MODULE'] ? this.filteredValues['MODULE'] : 'MW01' },
      set(val) {
        // this.loading = true

        // let filteredValues___ = { ...this.filteredValues }

        this.updateFilteredValues({ MODULE: val }).then(() => {
          this.sendRequest('filtered')
          // .then(() => { this.loading = false })
        })
      }
    },
  },
  created() {
    this.queryLibService = new DBSupportService()
    this.getModuleOptions()
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'sendRequest', 
      'updateFilteredValues'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 
    ]),
    getModuleOptions() {
      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Threed Module List',
        filters: ""
      }).then(res => {
        this.moduleOptions = res ? res.map(d => d.MODULE) : []
        this.moduleDesc = res ?  res.map(d => {
          return {
            key: d.MODULE,
            desc: d.MODULE_DESCR
          }
        })
        : []
      })
    }
  }
}
</script>