<template>
  <div :id="localId">
    <v-dialog 
      v-model="opened" 
      class="modal_popup_free_content_wrapper"
      :persistent="persistent" 
      :hide-overlay="!overlay" 
      :width="width"
    >
      <component
        v-if="component"
        v-model="opened" 
        :is="component"
        :params="params"
        @complete="onCompCreated"
        @call-sender="onCallSender"
      />
    </v-dialog>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import JModalPopupFreeComponents from './JModalPopupFreeComponents'

export default {
  name: 'k-modal-popup-free',
  mixins: [
    SafeIdMixin
  ],
  components: {
    ...JModalPopupFreeComponents
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
        component: '',
        queryid: -1,
        filters: {},
        data: {
          title: '',
          persistent: true,
          overlay: true,
        }
      })
    },
    value: null,
  },
  data: () => ({
    width: 0,
  }),
  computed: {
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    persistent() { return this.params.data && this.params.data.persistent },
    overlay() { if(!this.params.data) return true; else return this.params.data.overlay; },
    component() { return this.params.component },
  },
  created() {
    this.localId = this.id || 'k-modal-popup-free__' + this.safeId('')
  },
  methods: {
    onCallSender(d) {
      this.$emit('call-sender', d)
    },
    onCompCreated(conf) {
      this.width = conf.width
    },
  }
}


</script>
