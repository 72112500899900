<template>
  <div :id="localId">
    <v-card>
      <v-card-title class="drag_activation_region">
        <div class="component_header_search_wrapper">
          <input v-model="searchInput" type="text" class="search_input" placeholder="(*) Filtering columns" />
          <button v-ripple class="button_clear_input" title="Close" @click="onClearInput">
            <i class="mdi mdi-close"></i>
          </button>
          <button v-ripple class="button_search" title="Close">
            <i class="mdi mdi-magnify"></i>
          </button>
        </div>

        <div v-ripple class="component_header_action_wrapper">
          <button class="button_close" title="Close" @click="onClose">
            <i class="mdi mdi-close"></i>
          </button>
        </div>

        <div class="component_header">
          <div class="header_sector_1">
            <div class="component_title_wrapper">
              <div class="main">{{ title }}</div>
              <div class="sub">{{ subtitle }}</div>
            </div>
            <div></div>
          </div>
          <div class="header_sector_2">
            <span v-for="(h, i) in header" :class="`column${i}`" :key="`eq_filtered_list__header_column_${i}`">
              {{ h.text }}
            </span>
          </div>
        </div>
      </v-card-title>
      <div class="component_content_wrapper">
        <div v-for="(item, i) in filteredItems" :class="`item_row_${item.NO}`" :key="`eq_filtered_list__item_row_${i}`" @click="onItemClick(item)">
          <div v-for="(h, j) in header" :class="`cell column${j%colcnt}`" :key="`eq_filtered_list__item_row_column_${j}`">
            <span v-if="h.text=='No.'" class="no">{{ i+1 }}</span>
            <div v-else-if="h.colName=='TAG_NO'" :class="`constage rect ${item.STATUS_CODE.toLowerCase()} ${item.CRITICAL.toLowerCase()}`" :style="`background-color: ${stageBarColor(item.STATUS_CODE)}`" :title="stageTitle(item)">{{ item.TAG_NO }}</div>
            <span v-else-if="tooltips.includes(h.colName)" :title="item[h.colName]">{{ item[h.colName] }}</span>
            <span v-else>{{ item[h.colName] }}</span>
          </div>
        </div>
      </div>
      <div class="component_footer">
        <div class="total">
          <span class="label_">Total</span>
          <span class="value">{{ total }}</span>
          <span class="separator"></span>
          <span class="label_">Filtered</span>
          <span class="value">{{ filtered }}</span>
        </div>
        <div class="notice_wrapper">
          {{ notice }}
        </div>
        <div class="legend">
          <div v-for="(s, i) in poStage" class="stage_marker_wrapper" :key="`eq_stage_mark__${i}`">
            <span :class="`stage_mark ${s.code.toLowerCase()}`" :style="`background-color: ${s.bcolor}`"></span>
            <span class="stage_name">{{ s.name }}</span>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { DBSupportService } from '@/services'

export default {
  name: 'k-eq-filtered-list',
  mixins: [
    SafeIdMixin
  ],
  props: {
    params: {
      type: Object,
      default: () => ({
        open: true,
        component: '',
        queryid: -1,
        queryname: 'Heavy Lifting Items',
        filters: {},
        filterString: '',
        data: {
          title: '',
          persistent: true,
          overlay: true,
        }
      })
    },
    value: null,
  },
  data: () => ({
    header: [
      { text: 'No.'         , colName: 'NO'           },
      { text: '(*)Item Desc', colName: 'DESCRIPTION'  },
      { text: '(*)Tag No.'  , colName: 'TAG_NO'       },
      { text: '(*)Supplier' , colName: 'SUPPLIER_CODE'},
      { text: 'Package'     , colName: 'PACKAGE'      },
      { text: 'Proc. Unit'  , colName: 'PROCESS_UNIT' },
      { text: '(*)MR No.'   , colName: 'MR_NO'        },
      { text: '(*)PO No.'   , colName: 'PO_NO'        },
    ],
    total: '0',
    filtered: '0',
    colcnt: 9,
    items: [],
    prevCondition: '',
    poStage: [
      { code: 'MR'      , name: 'MR'         , bcolor: '#e3e3e3' },
      { code: 'LOA'     , name: 'LOA'        , bcolor: '#9693fc' },
      { code: 'PO'      , name: 'PO'         , bcolor: '#ffc107' },
      { code: 'DELIVERY', name: 'Delivery'   , bcolor: '#64d969' },
      { code: 'INSTALL' , name: 'Install'    , bcolor: '#959595' },
      { code: 'NOT'     , name: 'Not started', bcolor: '#e3e3e3' },
    ],
    poCritical: [
      { code: 'P2W', name: 'On-site delivery over 2 weeks from ROS'  , tcolor: '#ec407a' },
      { code: 'W2W', name: 'On-site delivery within 2 weeks from ROS', tcolor: '#39b1e6' },
      { code: 'NMR', name: 'Not the MR Item'                         , tcolor: '#e99709' },
      { code: 'NPO', name: 'Not the PO item'                         , tcolor: '#65b738' },
    ],
    tooltips: ['DESCRIPTION'],
    notice: '',
    searchInput: '',
    filterValues: [
      'DESCRIPTION'   ,
      'TAG_NO'        ,
      'SUPPLIER_CODE' ,
      'MR_NO'         ,
      'PO_NO'         ,
    ]
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [ 'sharedData' ]),

    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    title() { return this.params.data.title.toUpperCase() },
    subtitle() {
      let subtitle__ = this.params.filters.ORIGIN_LONGITUDE ? 'Lng./Lat. Location' : (this.params.filters.LLE || 'All the PO Data')
      return (`Filtered On ${subtitle__}`).toUpperCase()
    },
    queryName() { return this.params.queryname },
    filters() { return this.params.filters },
    filterString() { return this.params.filterString },
    filteredItems() {
      if(!this.searchInput) var items_ =  this.items
      else items_ = this.items.filter(d => this.filterValues.some(f => d[f] && d[f].toLowerCase().includes(this.searchInput.toLowerCase())))
      
      this.filtered = items_.length.toLocaleString()
      return items_
    }
  },
  watch: {
    params: {
      handler(val) { this.getFilteredResult() },
      deep: true
    },
    sharedData: {
      handler(d) { this.setItemrowSelected(d) },
      deep: true
    },
  },
  created() {
    this.localId = this.id || 'k-eq-filtered-list__' + this.safeId('')
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    this.prevCondition = 'init'
    this.getFilteredResult()

    setTimeout(() => {
      let elbox = document.querySelector(`#${this.localId} .component_header`).getBoundingClientRect()
      this.$emit('complete', { width: elbox.width + 16*2 + 10 })
    }, 500)
  },
  methods: {
    onItemClick(d) {
      this.setItemrowSelected(d)
      this.notice = ''

      if(!d.ORIGIN_LATITUDE || !d.ORIGIN_LONGITUDE) {
        this.notice = 'Unavailable item on the Vendor Map.'
        return
      }

      this.$emit('call-sender', d)
    },
    onClose() {
      this.opened = false
    },
    onClearInput() {
      this.searchInput = ''
    },
    onFilter(e) {
      
    },
    getFilteredResult() {
      let filters_ = JSON.stringify(this.filters)

      if(this.prevCondition == filters_ + this.filterString) return

      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'China1 Vendor Map PO Items',
        filterType: 'json',
        filters: filters_,
        filterString: this.filterString
      }).then(res => {
        this.items = res ? res : []
        this.total = this.items.length.toLocaleString()
      })

      this.prevCondition = filters_ + this.filterString
    },

    setItemrowSelected(d) {
      let elScreen = document.querySelector(`#${this.localId} .component_content_wrapper`)
      let el = document.querySelector(`#${this.localId} .item_row_${d.NO}`)

      if(!el) return

      let offsetMargin = 81
      let screenHeight = 315
      let rowHeight = 21
      let scrollTop = elScreen.scrollTop
      let offsetTop = el.offsetTop
      let elTopLess = offsetTop - offsetMargin - scrollTop
      let elTopOver = (offsetTop + rowHeight) - offsetMargin - scrollTop - screenHeight
      let scrollTopLess = scrollTop + elTopLess - screenHeight / 2
      let scrollTopOver = scrollTop + elTopOver + screenHeight / 2

      if(elTopOver > 0) elScreen.scrollTo({ top: scrollTopOver, behavior: 'smooth' })
      else if(elTopLess < 0) elScreen.scrollTo({ top: scrollTopLess, behavior: 'smooth' })

      let elSelected = document.querySelector(`#${this.localId} [class^="item_row"].selected`)
      if(elSelected) elSelected.classList.remove('selected')
      
      el.classList.add('selected')
    },
    stageTitle(d) {
      let stages = []

      let item = this.poStage.find(c => c.code == d.STATUS_CODE)
      if(item) stages.push(item.name)

      item = this.poCritical.find(c => c.code == d.CRITICAL)
      if(item) stages.push(item.name)

      return stages.join(' / ')
    },
    stageBarColor(code) {
      return this.poStage.find(s => s.code == code).bcolor
    },
  }
}
</script>

<style lang="stylus" scoped>
.column0 {
  width: 3rem;
  text-align: center;

  .no {
    color: #ccc;
  }
}
.column1 {
  width: 18rem;
}
.column2 {
  width: 10.5rem;
}
.column3 {
  width: 6rem;
  text-align: center;
}
.column4 {
  width: 5rem;
  text-align: center;
}
.column5 {
  width: 8rem;
  text-align: center;
}
.column6 {
  width: 14rem;
}
.column7 {
  width: 18rem;
}

.drag_activation_region {
  position: relative;
  padding-bottom: 0 !important;

  .component_header_action_wrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    padding-top: .1rem;
    padding-left: .2rem;

    .mdi-close {
      font-size: 1.4rem;
    }
  }

  .component_header_search_wrapper {
    position: absolute;
    top: 1.8rem;
    right: 7rem;

    .search_input {
      width: 18rem;
      height: 2.2rem;
      line-height: 1.8rem;
      padding-left: .4rem;
      padding-right: 3.7rem;
      border: 1px solid #ccc;
      border-radius: .2rem;
    }
    .search_input::placeholder {
      color: #bbb;
    }

    .button_clear_input,
    .button_search {
      position: absolute;
      top: .3rem;

      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      
      .mdi {
        display: block;
        color: #999;
        font-size: 1.6rem;

        &.mdi-close {
          font-size: 1.2rem;
          padding-top: .2rem;
        }
        &.mdi-magnify {
          margin-top: -.1rem;
        }
      }
    }

    .button_clear_input {
      right: 2rem;
    }
    .button_search {
      right: .3rem;
    }
  }
}

.component_header {
  pointer-events: none;

  .header_sector_1 {
    display: flex;
    justify-content: space-between;

    margin-bottom: 1rem;

    .component_title_wrapper {
      .main {
        font-size: 1rem;
        color: #000;
      }
      .sub {
        font-size: 1rem;
        color: #999;
      }
    }
  }
  .header_sector_2 {
    display: flex;

    span {
      display: block;

      line-height: 2.4rem;
      padding: 0 .5rem;
      border-bottom: 1px solid #999;
    }
  }
}

.component_content_wrapper {
  padding: 0 1.6rem;
  height: 31.5rem;
  color: #666;

  overflow: auto;
  background-color: #fff;

  [class^='item_row'] {
    display: flex;
    align-content: center;

    &.selected {
      transition: background-color .15s;
      background-color: #efefef;
    }

    .cell {
      white-space: nowrap;
      overflow: hidden;

      height: 2.1rem;
      padding: .3rem .5rem 0 .5rem;
      border-bottom: 1px solid #efefef;
    }

    .constage {
      height: 1.6rem;
      margin-top: -.1rem;
      padding-top: .1rem;

      &.circle {
        width: 1.6rem;
        border-radius: 50%;
      }
      &.rect {
        padding-left: .3rem;
        border-radius: .2rem;
      }

      &.p2w {
        color: #ec407a;
      }
      &.w2w {
        color: #39b1e6;
      }
      &.nmr {
        color: #e99709;
      }
      &.npo {
        color: #65b738;
      }
    }
  }
  [class^='item_row']:hover {
    cursor: default;
    transition: background-color .15s;
    background-color: #efefef;
  }
}

.component_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 4rem;
  padding: .5rem 1.6rem;

  .total {
    span {
      &.label_ {
        color: #bbb;
        padding-right: .5rem;
      }
      &.value {
        color: #000;
      }
      &.separator {
        margin: 0 1rem;
        border-left: 1px solid #999;
      }
    }
  }

  .notice_wrapper {
    color: #f33f3f;
  }

  .legend {
    display: flex;

    .stage_marker_wrapper {
      display: flex;
      align-items: center;

      span {
        display: block;

        &.stage_mark {
          width: 1.1rem;
          height: 1.1rem;
          margin-left: 1.5rem;
          border-radius: .2rem;
        }
        &.stage_name {
          margin-left: .3rem;
          padding-top: .2rem;
          font-size: .9rem;
        }
      }
    }
  }
}
</style>