import { render, staticRenderFns } from "./HeavyLiftingItems.vue?vue&type=template&id=0f5ba799&scoped=true&"
import script from "./HeavyLiftingItems.vue?vue&type=script&lang=js&"
export * from "./HeavyLiftingItems.vue?vue&type=script&lang=js&"
import style0 from "./HeavyLiftingItems.vue?vue&type=style&index=0&id=0f5ba799&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5ba799",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardTitle})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
