<template>
  <div :id="localId">
    <v-card>
      <v-card-title class="drag_activation_region">
        <div v-ripple class="component_header_action_wrapper">
          <button class="button_close" title="Close" @click="onClose">
            <i class="mdi mdi-close"></i>
          </button>
        </div>

        <div class="component_header">
          <div class="header_sector_1">
            <div class="component_title_wrapper">
              <div class="main">{{ title + ' ' + task }}</div>
              <div class="sub">{{ subtitle }}</div>
            </div>
            <div></div>
          </div>
        </div>
      </v-card-title>
      
      <div class="component_content_wrapper">
        <div v-if="showEqList" class="ep_detail_list wrapper">
          <label class="pos">Equipments</label>
          <div class="list_header">
            <span 
              v-for="(col, i) in cols" 
              class="list_header_column column" 
              :key="i" 
              :style="`width: ${epColProps[col].width}px; text-align: ${epColProps[col].align};`"
            >
              {{ epColProps[col].label }}
            </span>
          </div>
          <div class="list_body">
            <div v-for="(item, i) in items" class="list_row" :key="i" @click="e => { onTargetItemClick(item) }">
              <span 
                v-for="(col, j) in cols" 
                class="list_row_column column" 
                :key="j" 
                :style="`width: ${epColProps[col].width}px; text-align: ${epColProps[col].align};`"
                :title="item[col]"
              >
                {{ item[col] }}
              </span>
            </div>
          </div>
        </div>
        <div class="ep_detail_sector">
          <div class="ep_detail_left">
            <div class="ep_detail_item wrapper">
              <label class="pos">Equipment (Picking)</label>
              <div class="group_box">
                <div class="group_row">
                  <span class="title_name">Item Desc.</span>
                  <span class="data">{{ eqItem.ITEM_DESC }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Tag No.</span>
                  <span class="data">{{ eqItem.TAG_NO }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Manufacture</span>
                  <span class="data">{{ eqItem.MANUFACTURE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Size (L*W*H) (cm)</span>
                  <span class="data">{{ eqItem.SIZE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Weight (t)</span>
                  <span class="data">{{ eqItem.WEIGHT }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">QTY</span>
                  <span class="data">{{ eqItem.QTY }}</span>
                </div>
              </div>
            </div>
            <div class="ep_detail_v_spacer"></div>
            <div class="ep_detail_item wrapper">
              <label class="pos">Logistics</label>
              <div class="group_box">
                <div class="group_row">
                  <span class="title_name">Vendor</span>
                  <span class="data">{{ eqItem.VENDOR }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Voyage Plan</span>
                  <span class="data">{{ eqItem.VOYAGE_PLAN }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Shipment Type</span>
                  <span class="data">{{ eqItem.SHIPMENT_TYPE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">DWT</span>
                  <span class="data">{{ eqItem.DWT }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">SPMT/Offloading Mode</span>
                  <span class="data">{{ eqItem.TRANS_SPMT_UPLOAD_MODE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">PPU</span>
                  <span class="data">{{ eqItem.PPU }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ep_detail_right">
            <div class="ep_detail_item wrapper">
              <label class="pos">Transport Plan (Schedule)</label>
              <div class="group_box">
                <div class="group_row">
                  <span class="title_name">Cargo Readiness</span>
                  <span class="data">{{ eqItem.READY_DATE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Loading Date</span>
                  <span class="data">{{ eqItem.LOADING_DATE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Sailing Period</span>
                  <span class="data">{{ eqItem.SEA_TRANS_DATE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Mileage (nm)</span>
                  <span class="data">{{ eqItem.MILEAGE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Offloading Date</span>
                  <span class="data">{{ eqItem.OFFLOADING_DATE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Discharging</span>
                  <span class="data">{{ eqItem.ETA_SITE_DATE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Picking Up Place</span>
                  <span class="data">{{ eqItem.PICKUP_PLACE }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Shipping Port</span>
                  <span class="data">{{ eqItem.SHIPPING_PORT }}</span>
                </div>
                <div class="group_row">
                  <span class="title_name">Arrival Port</span>
                  <span class="data">{{ eqItem.ARRIVAL_PORT }}</span>
                </div>
              </div>
            </div>
            <div class="ep_detail_v_spacer"></div>
            <div class="ep_detail_item wrapper h100">
              <label class="remarks">Remarks</label>
              <div class="group_box">
                <div class="group_row">
                  <span class="data">{{ eqItem.REMARK }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="component_footer">
      </div>
    </v-card>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { DBSupportService } from '@/services'

export default {
  name: 'k-eq-filtered-list',
  mixins: [
    SafeIdMixin
  ],
  props: {
    params: {
      type: Object,
      default: () => ({
        open: true,
        component: '',
        queryid: -1,
        filters: {},
        iFilters: {},
        data: {
          title: '',
          persistent: true,
          overlay: true,
        }
      })
    },
    value: null,
  },
  data: () => ({
    items: [],
    eqItem: {},
    prevCondition: '',
    epColProps: {
      ITEM_DESC: {
        label: 'Item Desc.',
        align: 'left',
        width: 200
      },
      TAG_NO: {
        label: 'Tag No.',
        align: 'left',
        width: 100
      },
      MANUFACTURE: {
        label: 'Manufacture',
        align: 'left',
        width: 100
      },
      SIZE: {
        label: 'Size (cm)',
        align: 'left',
        width: 110
      },
      WEIGHT: {
        label: 'Weight (t)',
        align: 'center',
        width: 60
      },
      QTY: {
        label: 'QTY',
        align: 'center',
        width: 60
      },
    },
  }),
  computed: {
    cols() { return Object.keys(this.epColProps) },
    filters() { return this.params.filters },
    filterString() { return this.params.filterString },
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    showEqList() { return this.items.length > 0 },
    subtitle() { return 'BASED ON LOGISTICAL INFORMATION SUBMITTED BY VENDOR' },
    title() { return this.params.data.title.toUpperCase() },
    task() { return this.params.filters.TASK.toUpperCase() },
    where() { return `MOF_SHIPMENT = '${this.task}'` },
  },
  watch: {
    'params.filters': {
      handler(val) {
        this.getFilteredResult()
      },
      deep: true
    },
  },
  created() {
    this.localId = this.id || 'ci-eq-filtered-list__' + this.safeId('')
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    this.prevCondition = 'none'
    this.getFilteredResult()

    setTimeout(() => {
      let elbox = document.querySelector(`#${this.localId} .list_header`).getBoundingClientRect()
      // this.$emit('complete', { width: elbox.width + 16*2 + 10 })
      this.$emit('complete', { width: 785 })
    }, 500)
  },
  methods: {
    onClose() {
      this.opened = false
    },
    onTargetItemClick(d) {
      this.eqItem = d
    },

    getFilteredResult() {
      if(this.prevCondition == this.where) return

      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Cargo Information Filtered By Condition',
        filters: this.where
      }).then(res => {
        this.items = res ? res : []
        if(this.items.length > 0) this.eqItem = this.items[0]
      })

      this.prevCondition = this.where
    },
  }
}
</script>

<style lang="stylus" scoped>
.drag_activation_region {
  position: relative;
  padding-bottom: 0 !important;

  .component_header_action_wrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    padding-left: .1rem;

    .mdi-close {
      font-size: 1.4rem;
    }
  }

  .component_header {
    padding-bottom: 1rem;
    pointer-events: none;
    
    .header_sector_1 {
      display: flex;
      justify-content: space-between;

      margin-bottom: 1rem;

      .component_title_wrapper {
        .main {
          font-size: 1rem;
          color: #000;
        }
        .sub {
          font-size: 1rem;
          color: #999;
        }
      }
    }
  }
}

.component_content_wrapper {
  padding: 0 1.6rem;

  .wrapper {
    position: relative;

    padding: 1rem;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 3px;

    label {
      position: absolute;
      top: -.6rem;
      left: .5rem;

      line-height: 1.2rem;
      padding: 0 1rem;
      background-color: #fff;
    }

    &.h100 {
      height: 7.6rem;
    }
  }

  .ep_detail_list {
    position: relative;
    margin-bottom: 1rem;

    span {
      display: block;

      white-space: nowrap;
      margin-right: 1rem;

      pointer-events: none;
      overflow: hidden;
    }

    .list_header {
      position: absolute;
      top: 0;

      display: flex;
      padding: 1rem 0 .3rem 0;
      border-bottom: 1px solid rgb(229, 229, 229); 

      .list_header_column {
        color: #999;
      }
    }

    .list_body {
      max-height: 8.5rem;
      margin-top: 1.9rem;
      overflow: auto;

      .list_row {
        display: flex;
        padding: .1rem 0;
      }
      .list_row:hover {
        background-color: #eee;
      }
    }
  }

  .ep_detail_sector {
    display: flex;

    .ep_detail_v_spacer {
      height: 1rem;
    }

    .ep_detail_left {
      margin-right: .5rem;
      width: 45rem;
    }
    .ep_detail_right {
      margin-left: .5rem;
      width: 30rem;
    }
  }
  .group_row {
    display: flex;
    align-items: flex-start;

    span {
      display: block;
      line-height: 1.8rem;
      pointer-events: none;

      &.title_name {
        color: #9e9e9e;
        text-align: right;
        margin-right: 1rem;
        min-width: 10rem;
      }
    }
  }
}

.component_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 4rem;
  padding: .5rem 1.6rem;
}
</style>
