<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="j_comm_comments"
    style="z-index: 999;"
    :id="localId"
    :opacity="0"
  >
    <j-comm-comments-contents
      v-model="opened" 
    />
  </j-overlay>
</template>

<script>
import * as d3 from 'd3'

import { SafeIdMixin } from '@/mixins/safeid.mixin'
import JCommCommentsContents from './JCommCommentsContents.vue'

export default {
  name: 'j-modal-slide-component---comments',
  mixins: [
    SafeIdMixin
  ],
  components: {
    JCommCommentsContents
  },
  props: {
    value: null,
  },
  computed: {
    opened: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    }
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onClose)
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    onKeydown(e) {
      if(e.key == 'Escape') this.onClose()
    },
    onClose() {
      this.opened = false
    },
  }
}
</script>
