<template>
  <v-dialog hide-overlay content-class="snb_user_info_wrapper" v-model="opened" :width="350">
    <v-card>
      <div class="snb_user_info_inner_wrapper" v-if="account">
        <a 
          class="btn-close" title="find member(s)" 
          @click="onClose"
        >
          <i class="mdi mdi-close"></i>
        </a>

        <div class="snb_user_info" >
          <div class="member-info">
            <div class="member-name">
              <span class="name">{{account.userName}}</span>
            </div>
            <div class="member-position">
              <span class="position">{{account.position}}</span> /
              <span class="department">{{account.office}}</span>
            </div>
          </div>

          <div class="links">
            <a @click="changePassword=true">Change Password</a>
            <div v-if="isSvcAdmin" class="gotoadmin">
              <span class="separator">|</span> 
              <router-link to="/service/account_management/account_management/user_service/user_accounts">Go to admin</router-link>
            </div>
          </div>

          <div v-if="changePassword" class="password_inputs">
            <div class="input_wrapper">
              <span>Current Password</span>
              <input 
                type="password" 
                name="curpassword" 
                v-model="curPassWord"
                :rules="curPasswordRules"
              />
            </div>
            <div class="input_wrapper">
              <span></span>
            </div>
            <div class="input_wrapper">
              <span></span>
            </div>
            <div class="input_wrapper">
              <span>New Password</span>
              <input 
                type="password" 
                name="newpassword" 
                v-model="newPassWord"
                :rules="newPasswordRules"
              />
            </div>
            <div class="input_wrapper">
              <span>Confirm Password</span>
              <input 
                type="password" 
                name="confirm" 
                v-model="cfmPassWord"
                :rules="cfmPasswordRules"
              />
            </div>
            <!-- <div class="input_wrapper">
              <span>OTP Code</span>
              <input 
                type="text" 
                name="otpCode" 
                v-model="otpCode"
                :rules="otpRules"
              />
            </div> -->
            <div class="changed" :class="[messagered ? 'messagered':'messagegreen']">{{changed}}</div>
            <div class="button_wrapper">
              
              <button v-ripple @click="Changes">Change</button>
              <button v-ripple class="cancle" @click="changePassword=false">{{cancle}}</button>
            </div>
          </div>
          
        </div>

        <div class="info-bar"></div>
        
      </div>
    </v-card>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </v-dialog>
</template>
<script>
import '@/assets/stylus/ui/component/_jSnbUserInfo.styl'
import __C from '@/primitives/_constant_'
import PasswordEncMixin from '@/mixins/password.enc'
import { mapActions, mapState, mapMutations } from 'vuex'
import { AdminService } from "@/services"
export default {
  mixins: [
    PasswordEncMixin
  ],
  data: () => ({
    otpCode: '999999', // 사용자 입력 OTP 코드
    messagered:'',
    changePassword: false,
    selectedItem: {},
    adminService: null,
    changed:'',
    cancle:'Cancle',
    curPassWord: '',
    cfmPassWord: '',
    newPassWord: '',
    curPasswordRules: [
      v => !!v || 'Current Password is required', // 필수 입력 검사
    ],
    newPasswordRules: [
      v => !!v || 'Required',
      v => (!!v && v.length >= 14) || 'At leat 14 characters required',
      v => /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) 
            || '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)',
    ],
    cfmPasswordRules: [
      v => !!v || 'Required',
      v => (!!v && v.length >= 14) || 'At leat 14 characters required',
      v => /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) 
            || '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)',
    ],
    otpRules: [
      v => !!v || 'OTP is required', // 필수 입력 검사
      v => /^\d{6}$/.test(v) || 'OTP must be exactly 6 digits', // 6자리 숫자 검사
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { }
  }),
  props: {
    value: null
  },  
  watch: {
    changePassword(v) {
      if (v == false) {
        this.curPassWord = ''
        this.newPassWord = ''
        this.cfmPassWord = ''
        this.changed = ''
        this.cancle ='Cancle'
      }
    },
    curPassWord(v) {
      if (v.length == 0) this.changed = ''
      if (!v){
        this.messagered = true
        this.changed = 'Current Password is required'
      } else {
        this.messagered = false
        this.confRule()
      }
    },
    newPassWord(v) {
      if (v.length == 0) this.changed = ''
      if (v.length < 14) { 
        this.messagered = true
        this.changed ='At leat 14 characters required'
      } else if(!/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) ){
        this.messagered = true
        this.changed = '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)'
      } else {
        this.messagered = false
        this.confRule()
      }
    },
    cfmPassWord(v) {
      if (v.length == 0) this.changed = ''
      if (v.length < 14) { 
        this.messagered = true
        this.changed ='At leat 14 characters required'
      } else if(!/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) ){
        this.messagered = true
        this.changed = '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)'
      } else {
        this.messagered = false
        this.confRule()
      }
    },
    otpCode(v) {
      if (v.length == 0) this.changed = ''
      if (!/^\d{6}$/.test(v)){
        this.messagered = true
        this.changed = 'OTP must be exactly 6 digits.'
      } else {
        this.messagered = false
        this.confRule()
      }
    }
  },
  created() {
    this.cfmPasswordRules.push(this.confRule)
    this.adminService = new AdminService()
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['signout']),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, ['setUserInfoOpened',]),  
    onClose() {
      this.setUserInfoOpened(false)
      // this.$emit('closed')
    },
    async Changes() {
      this.changed = ''
      const curPasswordValid = this.curPasswordRules.every(rule => rule(this.curPassWord) === true)
      const newPasswordValid = this.newPasswordRules.every(rule => rule(this.newPassWord) === true)
      const cfmPasswordValid = this.cfmPasswordRules.every(rule => rule(this.cfmPassWord) === true)
      const otpValid = this.otpRules.every(rule => rule(this.otpCode) === true)
      // console.log('Confirm Value:', this.cfmPassWord);  // 디버깅용: confirm 값이 제대로 들어가 있는지 확인
      // console.log('Confirm Rules:', this.confirmationRules.map(rule => rule(this.cfmPassWord)));  // 각 규칙이 어떻게 평가되는지 확인
      
      if (!curPasswordValid) {
        this.changed = 'Current Password does not meet the requirements'
        this.messagered = true
        return
      }

      if (!newPasswordValid) {
        this.changed = 'New Password does not meet the requirements'
        this.messagered = true
        return
      }

      if (!cfmPasswordValid) {
        this.changed = 'Confirm password does not meet the requirements'
        this.messagered = true
        return
      }

      if (!otpValid) {
        this.changed = 'OTP Code does not meet the requirements'
        this.messagered = true
        return
      }

      if (this.newPassWord !== this.cfmPassWord) {
        this.changed = "Passwords do not match"
        this.messagered = true
        return
      }

      if (this.curPassWord === this.newPassWord) {
        this.changed = "New password cannot be the same as the temporary password"
        this.messagered = true
        return
      }

      this.selectedItem =  { ...this.account }
      this.selectedItem.password = this.newPassWord
      this.selectedItem.otpCode = this.otpCode
      
      if (this.selectedItem.password && this.selectedItem.password === this.cfmPassWord) {
        
        this.selectedItem.password = await this.setPasswordEnc(this.newPassWord)
        this.selectedItem.curPassword = await this.setPasswordEnc(this.curPassWord)

        this.adminService.updAdminMember(this.selectedItem, res => {
          if(res.data.error) {
            this.changed = res.data.message
            this.messagered = true
            return
          }
          
          this.msgInfo.type = "INFO"
          this.msgInfo.title = "Password Updated"
          this.msgInfo.titleDescription = ""
          this.msgInfo.message = "Your password has been changed successfully. Please log in again."
          this.msgInfo.buttonText[0] = "OK"
          this.msgInfo.button = [true, false, false]
          this.yes = () => {
            this.msgOpen = false
            this.changed ='Updated !'
            this.cancle ='Close'
            this.messagered = false
            this.signout()
          }
          this.msgOpen = true
        })
      } else if (!this.selectedItem.password || !this.cfmPassWord) {
        this.changed ='Please Enter Password'
        this.messagered = true
      } else {
        this.changed ='Passwords do not match'
        this.messagered = true
      }
    },
    confRule() {
      if (this.newPassWord === this.cfmPassWord){
        this.changed = 'Passwords match'
        this.messagered = false
        return true
      } else if (this.curPassWord === this.newPassWord){
        this.changed = 'New password cannot be the same as the temporary password'
        this.messagered = true
        return false
      } else {
        this.changed = 'Passwords do not match'
        this.messagered = true
        return false
      }
    },
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    isSvcAdmin() { return ['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) },
    opened: {
      get() { return this.value },
      set(val) { this.$emit('inputUser', val) },
    }
  }
}
</script>
