<template>
  <div class="lnb_checkbox">
    <span>{{ filterOption.label }}</span>
    <div class="option_group">
      <span v-if="all" class="all" @click="allclear()"><i class="mdi mdi-close"></i></span>
      <div class="group">
        <label class="group_title" :class="{ selected: groupSelected('00') }" @click="groupSelect(['00'])">Topside</label>
        <div class="items">
          <a 
            v-for="(option, i) in topside.children" 
            class="item" :class="{selected: option.selected}" 
            :key="'item'+i"
            @click="itemSelect(['00'], option.priority)"
          >
            <span></span>
            {{ option.name }}
          </a>
        </div>
      </div>
      <div class="group parent">
        <label class="group_title" :class="{ selected: groupSelected('10') }" @click="groupSelect(['10', '11', '12'])">Deckbox</label>
        <div class="items">
          <a 
            v-for="(option, i) in deckbox.children" 
            class="item" 
            :class="{selected: option.selected}" 
            :key="'item'+i"
            @click="itemSelect(['10'], option.priority)"
          >
            <span></span>
            {{ option.name }}
          </a>
        </div>
        <div class="group sub">
          <label class="group_title" :class="{ selected: groupSelected('11') }" @click="groupSelect(['11', '12'])">Deckhouse</label>
          <div class="items">
            <a 
              v-for="(option, i) in deckhouse.children"
              class="item" 
              :class="{selected: option.selected}" 
              :key="'item'+i"
              @click="itemSelect(['11'], option.priority)"
            >
              <span></span>
              {{ option.name }}
            </a>
          </div>
          <div class="group sub">
            <label class="group_title" :class="{ selected: groupSelected('12') }" @click="groupSelect(['12'])">All Levels in Deckhouse</label>
            <div class="items">
              <a 
                v-for="(option, i) in deckhouselevels.children"
                class="item" 
                :class="{selected: option.selected}" 
                :key="'item'+i"
                @click="itemSelect(['12'], option.priority)"
              >
                <span></span>
                {{ option.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="group">
        <label class="group_title" :class="{ selected: groupSelected('20') }" @click="groupSelect(['20'])">Hull</label>
        <div class="items">
          <a 
            v-for="(option, i) in hull.children"
            class="item" 
            :class="{selected: option.selected}" 
            :key="'item'+i"
            @click="itemSelect(['20'], option.priority)"
          >
            <span></span>
            {{ option.name }}
          </a>
        </div>
      </div>
      <label class="na" :class="{ selected: naselected }" @click="naSelect()">
        N/A
      </label>
    </div>
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_jLnbMSelectSCArea.styl'

import __C from '@/primitives/_constant_'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { MD2 } from '../services'
import Loading from '@/mixins/loading.mixin'

export default {
  name: 'j-lnb-s-c-area',
  mixins: [
    Loading
  ],
  props: {
    filterOption: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    scService: null,

    areaOptions: [],
    optionValues: {},

    compared: [],
    filterNames: [],
    naselected: false,

    notUpdatables: [
      'PUNCH_STATUS',
      'SUBSYSTEM_STATUS',
      'DISCIPLINE',
      'MOC_OPEN',
      'PMCS_OPEN',
      'DBSPO',
      'FLOAT_OFF',
      'OBS_OPEN',
      'SUBSYSTEM',
      'TAG',
      'PUNCH_NUMBER',
    ]
  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['filteredValues']),

    __C_() { return __C },

    all() {
      if(this.areaOptions.length === 0) return false
      if(this.areaOptions.findIndex(a_ => a_.selected) >= 0 || this.naselected) return true
      return false
    },

    topside() { 
      let group = this.areaOptions.find(options => options.code == '00') 
      return group ? group : { children: [] }
    },
    deckbox() { 
      let group = this.areaOptions.find(options => options.code == '10') 
      return group ? group : { children: [] }
    },
    deckhouse() { 
      let group = this.areaOptions.find(options => options.code == '11') 
      return group ? group : { children: [] }
    },
    deckhouselevels() { 
      let group = this.areaOptions.find(options => options.code == '12') 
      return group ? group : { children: [] }
    },
    hull() { 
      let group = this.areaOptions.find(options => options.code == '20') 
      return group ? group : { children: [] }
    },
  },
  created() {
    this.scService = new MD2.SystemCompletionService()
  },
  mounted() {
    this.getFilterOptionArea()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setFilteredValues'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'sendRequest', 
      'updateFilteredValues'
    ]),

    allclear() {
      this.groupUnselect()
      this.sendValuesChecked()
    },
    getFilterOptionArea() {
      this.scService.getFilterOptionArea().then(res => {
        this.setGroupOptions(res || [])
      })
    },
    groupSelect(code) {
      this.groupUnselect()

      let areaOptions_ = JSON.parse(JSON.stringify(this.areaOptions))
      code.forEach(c_ => {
        let group_ = areaOptions_.find(o_ => o_.code == c_)
        // .children.forEach(o_ => {
        //   o_.selected = true
        // })
        if(!group_) return

        group_.selected = true
        group_.children.forEach(o_ => {
          o_.selected = true
        })
      })
      this.areaOptions = areaOptions_
      this.sendValuesChecked()
    },
    groupSelected(code) {
      let group_ = this.areaOptions.find(o_ => o_.code == code)
      if(!group_) return false
      return !!group_.selected
    },
    groupUnselect() {
      let areaOptions_ = JSON.parse(JSON.stringify(this.areaOptions))
      let prevSelected = areaOptions_.filter(o_ => o_.selected)
      prevSelected.forEach(o_ => {
        o_.selected = false
        o_.children.forEach(c_ => {
          c_.selected = false
        })
      })
      this.areaOptions = areaOptions_
      this.naselected = false
    },
    itemSelect(code, id) {
      this.groupUnselect()

      let areaOptions_ = JSON.parse(JSON.stringify(this.areaOptions))
      code.forEach(c_ => {
        let group_ = areaOptions_.find(o_ => o_.code == c_)
        // .children.forEach(o_ => {
        //   o_.selected = true
        // })
        if(!group_) return

        group_.selected = true
        let item_ = group_.children.find(o_ => o_.priority == id)
        if(item_) item_.selected = true
      })
      this.areaOptions = areaOptions_

      this.sendValuesChecked()
    },
    naSelect() {
      this.groupUnselect()
      this.naselected = true

      this.sendValuesChecked()
    },
    sendValuesChecked() {
      this.loading = true

      let filteredValues_ = JSON.parse(JSON.stringify(this.filteredValues)) || {}
      if(filteredValues_ && filteredValues_[this.filterOption.colName]) delete filteredValues_[this.filterOption.colName]

      if(this.naselected) filteredValues_[this.filterOption.colName] = 'NULL'
      else {
        let groups_ = this.areaOptions.filter(g_ => g_.selected)
        if(groups_.length > 0) {
          let values_ = []
          groups_.forEach(g_ => {
            g_.children.forEach(c_ => {
              if(c_.selected) values_.push(c_.code)
            })
          })

          if(values_.length === 1) filteredValues_[this.filterOption.colName] = values_[0]
          else filteredValues_[this.filterOption.colName] = values_
        }
      }

      // trim filter to be stored in the local database.
      Object.keys(filteredValues_).forEach(k_ => {
        this.notUpdatables.forEach(nu_ => { if(nu_ == k_) delete filteredValues_[k_] })
      })
      this.updateFilteredValues(filteredValues_).then(() => {
        // set all filter again to be sent
        this.setFilteredValues(filteredValues_)
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
    setGroupOptions(res) {
      if(res.length === 0) {
        this.areaOptions = []
        return
      }
      let groups = [
        { code: '00', name: 'Topside', children: [] },
        { code: '10', name: 'Deckbox', children: [] },
        { code: '11', name: 'Deckhouse', children: [] },
        { code: '12', name: 'All Levels in Deckhouse', children: [] },
        { code: '20', name: 'Hull', children: [] },
      ]
      res.filter(option_ => option_.code != '[LABEL]').forEach(option_ => {
        let group__ = groups.find(g_ => g_.code == option_.group)
        if(!group__) return

        group__.children.push(option_)
      })

      if(this.filteredValues && this.filteredValues[this.filterOption.colName]) {
        if(this.filteredValues[this.filterOption.colName] == 'NULL') this.naselected = true
        else if(typeof this.filteredValues[this.filterOption.colName] == 'object') {
          this.filteredValues[this.filterOption.colName].forEach(o_ => {
            groups.forEach(g_ => {
              let selected = false
              g_.children.forEach(c_ => {
                if(c_.code == o_) c_.selected = selected = true
              })
              g_.selected = selected
            })
          })
        } else {
          groups.forEach(g_ => {
            let selected = false
            g_.children.forEach(c_ => {
              if(c_.code == this.filteredValues[this.filterOption.colName]) c_.selected = selected = true
            })
            g_.selected = selected
          })
        }
      }
      
      this.areaOptions = groups
    },
  }
}
</script>
