<template>
  <div :id="localId">
    <v-card>
      <v-card-title class="drag_activation_region">
        <div class="component_header_search_wrapper">
          <input v-model="searchInput" type="text" class="search_input" placeholder="(*) Filtering columns" />
          <button v-ripple class="button_clear_input" title="Close" @click="onClearInput">
            <i class="mdi mdi-close"></i>
          </button>
          <button v-ripple class="button_search" title="Close">
            <i class="mdi mdi-magnify"></i>
          </button>
        </div>

        <div v-ripple class="component_header_action_wrapper">
          <button class="button_close" title="Close" @click="onClose">
            <i class="mdi mdi-close"></i>
          </button>
        </div>

        <div class="component_header">
          <div class="header_sector_1">
            <div class="component_title_wrapper">
              <div class="main">{{ title }}</div>
              <div class="sub">{{ subtitle }}</div>
            </div>
            <div></div>
          </div>
          <div class="header_sector_2">
            <span v-for="(h, i) in header" :class="`column${i}`" :key="`eq_filtered_list__header_column_${i}`">
              {{ h.text }}
            </span>
          </div>
        </div>
      </v-card-title>
      <div class="component_content_wrapper">
        <div v-for="(item, i) in filteredItems" :class="`item_row_${item.TAG}`" :key="`eq_filtered_list__item_row_${i}`" @click="onItemClick(item)">
          <div v-for="(h, j) in header" :class="`cell column${j%colcnt}`" :key="`eq_filtered_list__item_row_column_${j}`">
            <span v-if="h.text=='No.'">{{ i+1 }}</span>
            <div v-else-if="h.colName=='SN'" :class="`status circle ${item.STATUS_CODE.toLowerCase()} ${item.CRITICAL.toLowerCase()}`" :style="`background-color: ${statusBarColor(item.STATUS_CODE)}`" :title="statusTitle(item)">{{ item.SN }}</div>
            <div v-else-if="h.colName=='TAG'" :class="`status rect ${item.STATUS_CODE.toLowerCase()} ${item.CRITICAL.toLowerCase()}`" :style="`background-color: ${statusBarColor(item.STATUS_CODE)}`" :title="statusTitle(item)">{{ item.TAG }}</div>
            <span v-else-if="tooltips.includes(h.colName)" :title="item[h.colName]">{{ item[h.colName] }}</span>
            <span v-else>{{ item[h.colName] }}</span>
          </div>
        </div>
      </div>
      <div class="component_footer">
        <div class="total">
          <span class="label_">Total</span>
          <span class="value">{{ total }}</span>
          <span class="separator"></span>
          <span class="label_">Filtered</span>
          <span class="value">{{ filtered }}</span>
        </div>
        <div class="notice_wrapper">
          {{ notice }}
        </div>
        <div class="legend">
          <div v-for="(s, i) in eqStatus" class="status_marker_wrapper" :key="`eq_status_mark__${i}`">
            <span :class="`status_mark ${s.code.toLowerCase()}`" :style="`background-color: ${s.bcolor}`"></span>
            <span class="status_name">{{ s.name  }}</span>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { DBSupportService } from '@/services'

export default {
  name: 'k-eq-filtered-list',
  mixins: [
    SafeIdMixin
  ],
  props: {
    params: {
      type: Object,
      default: () => ({
        open: true,
        component: '',
        queryid: -1,
        filters: {},
        data: {
          title: '',
          persistent: true,
          overlay: true,
        }
      })
    },
    value: null,
  },
  data: () => ({
    header: [
      { text: 'No.'       , colName: ''          },
      { text: 'SN'        , colName: 'SN'        },
      { text: 'Package'   , colName: 'LV2'       },
      { text: 'Area'      , colName: 'LV3'       },
      { text: 'Unit'      , colName: 'AREA_UNIT' },
      { text: '*Tag No.'  , colName: 'TAG'       },
      { text: '*Tag Desc.', colName: 'TAG_DESCR' },
      { text: '*MR No.'   , colName: 'MR_NO'     },
      { text: '*MR Desc.' , colName: 'MR_DESCR'  },
      // { text: 'Status'   , colName: 'STATUS'    },
    ],
    tooltips: ['TAG_DESCR', 'MR_DESCR'],
    total: '0',
    filtered: '0',
    colcnt: 9,
    items: [],
    prevCondition: '',
    eqStatus: [
      { code: 'MR'      , bcolor: '#e3e3e3', name: 'MR'          },
      { code: 'LOA'     , bcolor: '#9693fc', name: 'LOA'         },
      { code: 'PO'      , bcolor: '#ffc107', name: 'PO'          },
      { code: 'DELIVERY', bcolor: '#64d969', name: 'Delivery'    },
      { code: 'INSTALL' , bcolor: '#959595', name: 'Install'     },
      { code: 'NOT'     , bcolor: '#ffffff', name: 'Not started' },
    ],
    eqCritical: [
      { code: 'P2W', name: 'Over 2 weeks from ROS'   },
      { code: 'W2W', name: 'Within 2 weeks from ROS' },
      { code: 'NMR', name: 'Not the MR Item'         },
      { code: 'NPO', name: 'Not the PO Item'         },
    ],
    notice: '',
    searchInput: '',
    filterValues: [
      'TAG'      ,
      'TAG_DESCR',
      'MR_NO'    ,
      'MR_DESCR' ,
    ]
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [ 'sharedData' ]),

    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    title() { return this.params.data.title.toUpperCase() },
    subtitle() {
      let levels = []
      if(this.params.filters.LV2) levels.push(`Package ${this.params.filters.LV2}`)
      if(this.params.filters.LV3) levels.push(`Area ${this.params.filters.LV3}${this.params.filters.AREA_UNIT?' '+this.params.filters.AREA_UNIT:''}`)

      let subtitle__ = levels.length > 0 ? levels.join('. ') : 'Mainplant'

      return (`Direction (Filtered By) ${subtitle__}`).toUpperCase()
    },
    filters() { return this.params.filters },
    filterString() { return this.params.filterString },
    where() { 
      let filters_ = []

      for(const [k, v] of Object.entries(this.filters)) { filters_.push(`${k} = '${v}'`) }
      if(this.filterString) filters_.push(this.filterString)

      return filters_.join(' AND ')
    },
    filteredItems() {
      if(!this.searchInput) var items_ =  this.items
      else items_ = this.items.filter(d => this.filterValues.some(f => d[f] && d[f].toLowerCase().includes(this.searchInput.toLowerCase())))
      
      this.filtered = items_.length.toLocaleString()
      return items_
    }
  },
  watch: {
    'params.filters': {
      handler(val) {
        this.getFilteredResult()
      },
      deep: true
    },
    sharedData: {
      handler(d) { this.setItemrowSelected(d) },
      deep: true
    },
    searchInput() {
      this.onFilter()
    }
  },
  created() {
    this.localId = this.id || 'k-eq-filtered-list__' + this.safeId('')
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    this.prevCondition = 'init'
    this.getFilteredResult()

    setTimeout(() => {
      let elbox = document.querySelector(`#${this.localId} .component_header`).getBoundingClientRect()
      this.$emit('complete', { width: elbox.width + 16*2 + 10 })
    }, 500)
  },
  methods: {
    onItemClick(d) {
      this.setItemrowSelected(d)
      this.notice = ''

      if(!d.AREA_UNIT) {
        this.notice = 'Unavailable item in the layout view.'
        return
      }

      this.$emit('call-sender', d)
    },
    onClose() {
      this.opened = false
    },
    onClearInput() {
      this.searchInput = ''
    },
    onFilter(e) {
    },
    getFilteredResult() {
      if(this.prevCondition == this.where) return

      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Equipment Filtered Result',
        filters: this.where
      }).then(res => {
        this.items = res ? res : []
        this.total = this.items.length.toLocaleString()
      })

      this.prevCondition = this.where
    },

    setItemrowSelected(d) {
      let elScreen = document.querySelector(`#${this.localId} .component_content_wrapper`)
      let el = document.querySelector(`#${this.localId} .item_row_${d.TAG}`)

      if(!el) return

      let offsetMargin = 81
      let screenHeight = 400
      let rowHeight = 21
      let scrollTop = elScreen.scrollTop
      let offsetTop = el.offsetTop
      let elTopLess = offsetTop - offsetMargin - scrollTop
      let elTopOver = (offsetTop + rowHeight) - offsetMargin - scrollTop - screenHeight
      let scrollTopLess = scrollTop + elTopLess - screenHeight / 2
      let scrollTopOver = scrollTop + elTopOver + screenHeight / 2

      if(elTopOver > 0) elScreen.scrollTo({ top: scrollTopOver, behavior: 'smooth' })
      else if(elTopLess < 0) elScreen.scrollTo({ top: scrollTopLess, behavior: 'smooth' })

      let elSelected = document.querySelector(`#${this.localId} [class^="item_row"].selected`)
      if(elSelected) elSelected.classList.remove('selected')
      
      el.classList.add('selected')
    },
    statusTitle(d) {
      let status = []

      let item = this.eqStatus.find(c => c.code == d.STATUS_CODE)
      if(item) status.push(d.STATUS)

      item = this.eqCritical.find(c => c.code == d.CRITICAL)
      if(item) status.push(item.name)

      return status.join('. ')
    },
    statusBarColor(code) {
      return this.eqStatus.find(s => s.code == code).bcolor
    },
  }
}
</script>

<style lang="stylus" scoped>
.column0 {
  width: 3rem;
  text-align: center;
}
.column1 {
  width: 3rem;
  text-align: center;
}
.column2 {
  width: 5rem;
  text-align: center;
}
.column3 {
  width: 3.2rem;
  text-align: center;
}
.column4 {
  width: 3.2rem;
  text-align: center;
}
.column5 {
  width: 9rem;
}
.column6 {
  width: 20rem;
}
.column7 {
  width: 14rem;
}
.column8 {
  width: 20rem;
}
.column9 {
  width: 8rem;
}

.drag_activation_region {
  position: relative;
  padding-bottom: 0 !important;
  .component_header_action_wrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    padding-top: .1rem;
    padding-left: .2rem;

    .mdi-close {
      font-size: 1.4rem;
    }
  }

  .component_header_search_wrapper {
    position: absolute;
    top: 1.8rem;
    right: 7rem;

    .search_input {
      width: 18rem;
      height: 2.2rem;
      line-height: 1.8rem;
      padding-left: .4rem;
      padding-right: 3.7rem;
      border: 1px solid #ccc;
      border-radius: .2rem;
    }
    .search_input::placeholder {
      color: #bbb;
    }

    .button_clear_input,
    .button_search {
      position: absolute;
      top: .3rem;

      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      
      .mdi {
        display: block;
        color: #999;
        font-size: 1.6rem;

        &.mdi-close {
          font-size: 1.2rem;
          padding-top: .2rem;
        }
        &.mdi-magnify {
          margin-top: -.1rem;
        }
      }
    }

    .button_clear_input {
      right: 2rem;
    }
    .button_search {
      right: .3rem;
    }
  }
}

.component_header {
  pointer-events: none;
  .header_sector_1 {
    display: flex;
    justify-content: space-between;

    margin-bottom: 1rem;

    .component_title_wrapper {
      .main {
        font-size: 1rem;
        color: #000;
      }
      .sub {
        font-size: 1rem;
        color: #999;
      }
    }
  }
  .header_sector_2 {
    display: flex;

    span {
      display: block;

      line-height: 2.4rem;
      padding: 0 .5rem;
      border-bottom: 1px solid #999;
    }
  }
}

.component_content_wrapper {
  padding: 0 1.6rem;
  height: 40rem;
  color: #666;

  overflow: auto;
  background-color: #fff;

  [class^='item_row'] {
    display: flex;
    align-content: center;

    &.selected {
      transition: background-color .15s;
      background-color: #efefef;
    }

    .cell {
      white-space: nowrap;
      overflow: hidden;

      height: 2.1rem;
      padding: .3rem .5rem 0 .5rem;
      border-bottom: 1px solid #efefef;
    }

    .column0 {
      color: #ccc;
    }
    .status {
      height: 1.6rem;
      margin-top: -.1rem;
      padding-top: .1rem;

      &.circle {
        width: 1.6rem;
        border-radius: 50%;
      }
      &.rect {
        padding-left: .3rem;
        border-radius: .2rem;
      }

      &.p2w {
        color: #ec407a;
      }
      &.w2w {
        color: #39b1e6;
      }
      &.nmr {
        color: #e99709;
      }
      &.npo {
        color: #65b738;
      }
    }
  }
  [class^='item_row']:hover {
    cursor: default;
    transition: background-color .15s;
    background-color: #efefef;
  }
}

.component_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 4rem;
  padding: .5rem 1.6rem;

  .total {
    span {
      &.label_ {
        color: #bbb;
        padding-right: .5rem;
      }
      &.value {
        color: #000;
      }
      &.separator {
        margin: 0 1rem;
        border-left: 1px solid #999;
      }
    }
  }

  .notice_wrapper {
    color: #f33f3f;
  }

  .legend {
    display: flex;

    .status_marker_wrapper {
      display: flex;
      align-items: center;

      span {
        display: block;

        &.status_mark {
          width: 1.1rem;
          height: 1.1rem;
          margin-left: 1.5rem;
          border-radius: .2rem;
        }
        &.status_name {
          margin-left: .3rem;
          padding-top: .2rem;
          font-size: .9rem;
        }
      }
    }
  }
}
</style>