<template>
    <j-overlay
      id="jOverlayImgaWrapper"
      color="#000"
      opacity=".75"
      v-model="opened"
    >
      <div class="content_wrapper">
        <div class="photo_viewer">
          <j-button @click="onClose()" style="position: absolute; right: 8px; background: #fff; opacity: .6;">
            <v-icon>mdi-close</v-icon>
          </j-button>
          <img class="photo" id="photo_select" > 
        </div>
      </div>
    </j-overlay>
</template>

<script>
import '@/assets/stylus/ui/component/_jOverlayImgPopup.styl'

import * as d3 from 'd3'

import __C from '@/primitives/_constant_'
import { mapState } from 'vuex'
import { FetchStreamService } from "@/services"

export default {
  name: 'j-modal-overlay-img-popup',
  props: {
    value: null,
    imgPath: String,
    dimention: String
  },
  data: () => ({
    queryLibService: null,
    fetchStreamService: null,
    d: {},
    src: '',
    view: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', false) },
    }
  },
  watch: {
    opened(val) {
      if(!val) return
      this.getPhotoFile('photo_select')
    }
  },
  created() {
    this.fetchStreamService = new FetchStreamService()
    // this.queryLibService = new QueryLibService()
  },
  mounted() {
    d3.select('#jOverlayImgaWrapper').on('click', this.onClose)
    d3.select('#jOverlayImgaWrapper').on('click', this.onClose)
  },
  methods: {
    onClose() {
      this.opened = false
    },
    getPhotoFile(target) {
      let data = {
        token : this.account.token,
        path: this.imgPath
      }
      target = 'photo_select'
      this.fetchStreamService.selectPhotoFile(data)
      .then(response => response.blob())
      .then(blob => {
        let blobURL = URL.createObjectURL(blob)
        const img = document.getElementById(`${target}`)
        img.src = blobURL

        setTimeout(() => { this.setViewDimention() } ,150)
      })
    },
    
    setViewDimention() {
      
      let photoViewHeight = 870
      let dimention = this.dimention.split(',')

      if(!dimention || dimention.length === 0) return 
      
      let w_ = Number(dimention[0])
      let h_ = Number(dimention[1])
      let inW_ = 0
      let inH_ = 0

      if(h_ <= photoViewHeight) {
        inW_ = w_
        inH_ = h_
      } else {
        let ratio_ = photoViewHeight / h_
        inW_ = Math.round(w_ * ratio_)
        inH_ = photoViewHeight
      }
      d3.select('.photo_viewer')
      .transition()
      .duration(750)
      .style('width', `${inW_}px`)
      .style('height', `${inH_}px`)

      d3.select('.photo').transition().duration(500).style('opacity', 1)
    },
    
    onOverlayClick() {
      this.setImgPopupOpened = false
    },
  }
}


</script>
