<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="j-messenger"
    style="z-index: 6;"
    :id="localId"
    :opacity="0"
  >
    <div class="j-messenger--content-wrapper" :class="classWrapper">
      <div class="lnb">
        <div class="find-member-button-wrapper">
          <a 
            class="btn-menu" title="find member(s)" 
            :class="classMenuSelected(__C_.MESSENGER.MODE_FIND_MEMBER)" 
            @click="onMenuSelect(__C_.MESSENGER.MODE_FIND_MEMBER)"
          >
            <i class="mdi mdi-account-search"></i>
          </a>
          <div v-if="memberSelected.length" class="notice-member-selected">
            <span class="icon"><i class="mdi mdi-account"></i></span>
            <span class="count">{{ memberSelected.length }}</span>
          </div>
        </div>
        <div class="lnb-spacer"></div>
        <div class="talking-on-button-wrapper">
          <a 
            class="btn-menu" title="Talking On" 
            :class="classMenuSelected(__C_.MESSENGER.MODE_TALKING_ON)" 
            @click="onMenuSelect(__C_.MESSENGER.MODE_TALKING_ON)"
          >
            <i class="mdi mdi-chat"></i>
          </a>
          <div v-if="unreadMessages>0" class="unread-messages">{{ unreadMessages }}</div>
        </div>
        <div class="lnb-spacer"></div>
        <a 
          v-if="memberSelected1to1"
          class="btn-menu one2one" title="1to1 message" 
          :class="classMenuSelected(__C_.MESSENGER.MODE_1TO1_MESSAGE)" 
          @click="onMenuSelect(__C_.MESSENGER.MODE_1TO1_MESSAGE)"
        >
          <i class="mdi mdi-comment-outline"></i>
          <span class="label">1:1</span>
        </a>
        <div v-if="memberSelected1to1" class="lnb-spacer"></div>
        <a 
          v-if="isGroupChatOn"
          class="btn-menu" title="group message" 
          :class="classMenuSelected(__C_.MESSENGER.MODE_GROUP_MESSAGE)" 
          @click="onMenuSelect(__C_.MESSENGER.MODE_GROUP_MESSAGE)"
        >
          <i class="mdi mdi-comment-multiple-outline"></i>
        </a>
        <div v-if="isGroupChatOn" class="lnb-spacer"></div>
        <div class="notice-button-wrapper">
          <a 
            class="btn-menu" title="notice" 
            :class="classMenuSelected(__C_.MESSENGER.MODE_NOTICE)" 
            @click="onMenuSelect(__C_.MESSENGER.MODE_NOTICE)"
          >
            <i class="mdi mdi-message-text-outline"></i>
          </a>
          <div v-if="unreadNotices>0" class="unread-messages">{{ unreadNotices }}</div>
        </div>
      </div>
      <div class="content">
        <a v-ripple class="sub-menu---activator" title="sub menu" @click="subMenuOn=!subMenuOn">
          <i class="mdi mdi-dots-vertical"></i>
        </a>
        <div v-if="subMenuOn" class="sub-menu">
          <div 
            v-for="option in subMenuOptions" 
            class="sub-menu-item" 
            :key="option.index"
            @click="onSubMenuClicked(option.mode)"
          >
            <span class="sub-menu-label">{{ option.label }}</span>
          </div>
        </div>

        <div v-if="mode == __C_.MESSENGER.MODE_FIND_MEMBER" class="tab-find-members">
          <div class="tab-header">
            <span class="tab-title">Find Member(s)</span>
          </div>
          <div class="seach-box-wrapper">
            <div class="header">
              <a class="btn-search" title="search member" @click="onFindMembers">
                <i class="mdi mdi-account-search"></i>
              </a>
            </div>
            <input 
              v-model="inputMember" 
              class="input-find-member" 
              type="text" 
              placeholder="[ Type name of the member(s), or select the group(s). ]"
              @keyup.enter="onFindMembers" 
            />
            <div class="footer">
              <a v-if="!!inputMember" class="btn-clear" title="clear result" @click="onClearResult()">
                <i class="mdi mdi-close"></i>
              </a>
            </div>
          </div>
          <div class="find-member-result-description">
            <div class="find-result-box">
              <input 
                v-if="memberList.length > 0"
                v-model="memberSelectAll"
                id="member_found"
                type="checkbox" 
                @input="onSelectAllMembers(!memberSelectAll)"
              />
              <label 
                v-if="memberList.length > 0" 
                class="label-message" 
                for="member_found"
              >{{ memberList.length.toLocaleString() }} member{{ numSuffix(memberList) }} found.</label>
            </div>
            <div class="member-selected-box">
              <a v-if="memberSelected.length > 1" class="btn-goto-group-message" title="make a group chat" @click="onGotoGroupMessage">
                <i class="mdi mdi-comment-multiple-outline"></i>
              </a>
              <a v-if="memberSelected.length > 1" class="btn-send-notice" title="send notice" @click="onNotice(true)">
                <i class="mdi mdi-message-text-outline"></i>
              </a>
              <a v-if="memberSelected.length > 0" class="member-selected" title="go to list of selected members" @click="onSelectedMembers">
                {{ memberSelected.length.toLocaleString() }} member{{ numSuffix(memberSelected) }} selected.
              </a>
              <a v-if="memberSelected.length > 0" class="btn-clear" title="clear selected member(s)" @click="onClearSelected">
                <i class="mdi mdi-close"></i>
              </a>
            </div>
          </div>
          <div class="member-list">
            <div 
              v-for="member in memberList" 
              class="member-info-item"
              :class="classMemberSelected(member.sn)"
              :key="member.index"
              @dblclick="onOpen1To1Chat(member)"
              @click="onMemberSelect(member)"
            >
              <div class="avatar-wrapper">
                <v-avatar>
                  <v-icon></v-icon>
                </v-avatar>
                <div v-if="member.unreads>0" class="unread-messages">{{ member.unreads }}</div>
              </div>
              <div class="member-info">
                <div class="member-name">
                  <span class="name">{{ member.name }}</span>
                  <span class="date">{{ member.date }}</span>
                </div>
                <div class="member-position">
                  <span class="position">{{ member.position }}</span> /
                  <span class="department">{{ member.department }}</span>
                </div>
                <div class="last-message">{{ truncateMessage(member.lastMessage) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="mode == __C_.MESSENGER.MODE_TALKING_ON" class="tab-talking-on">
          <div class="tab-header">
            <span class="tab-title">Talking On</span>
          </div>
          <div class="tab-sub">
            <a v-ripple class="tab-1to1" :class="{ selected: !talkingONGroup }" title="1 To 1 talking on" @click="onTalkingOn(false)">
              <i v-if="!talkingONGroup" class="mdi mdi-account"></i>
              <i v-else class="mdi mdi-account-outline"></i>
            </a>
            <a v-ripple class="tab-group" :class="{ selected: talkingONGroup }" title="group talking on" @click="onTalkingOn(true)">
              <i v-if="talkingONGroup" class="mdi mdi-account-multiple"></i>
              <i v-else class="mdi mdi-account-multiple-outline"></i>
            </a>
          </div>

          <!-- 
            Next Job to do:
            2. Linked process, when 1to1 clicked then go to 1to1 chat, already implemented.
               Go to group chat-on when group talking-on double-clicked.
            3. UnreadNum, Last Message&Date by talkingONGroup, currently it is not distinguished.
            4. Read the message not bound any of the chat. It cannot reply to the sender.
          -->

          <div v-if="!talkingONGroup" class="member-list">
            <div 
              v-for="member in talkingONs" 
              class="member-info-item"
              :key="member.index"
              @dblclick="onOpen1To1Chat(member)"
            >
              <div class="avatar-wrapper">
                <v-avatar>
                  <v-icon></v-icon>
                </v-avatar>
                <div v-if="member.unreads>0" class="unread-messages">{{ member.unreads }}</div>
              </div>
              <div class="member-info">
                <div class="member-name">
                  <span class="name">{{ member.name }}</span>
                  <span class="date">{{ member.date }}</span>
                </div>
                <div class="member-position">
                  <span class="position">{{ member.position }}</span> /
                  <span class="department">{{ member.department }}</span>
                </div>
                <div class="last-message">{{ truncateMessage(member.lastMessage) }}</div>
              </div>
            </div>
          </div>
          <div v-else class="member-list">
            <div 
              v-for="group in talkingONs" 
              class="member-info-item"
              :key="group.index"
              @dblclick="onGotoGroupMessage(group)"
            >
              <div class="avatar-wrapper">
                <v-avatar>
                  <v-icon></v-icon>
                </v-avatar>
                <div v-if="group.unreads>0" class="unread-messages">{{ group.unreads }}</div>
              </div>
              <div class="member-info">
                <div class="member-name">
                  <span class="name">{{ group.chatName }}</span>
                  <span class="date">{{ group.date }}</span>
                </div>
                <div class="member-position">
                  <span class="position">{{ group.members }} members on chat</span>
                </div>
                <div class="last-message">{{ truncateMessage(group.lastMessage) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="mode == __C_.MESSENGER.MODE_1TO1_MESSAGE" class="tab-1to1-messages">
          <div class="tab-header">
            <span class="tab-title">1 : 1 Messages</span>
          </div>
          <div class="messages-header">
            <div class="member-info-item">
              <v-avatar>
                <v-icon></v-icon>
              </v-avatar>
              <div class="member-info">
                <div class="member-name">
                  <span class="name">{{ member1to1.name }}</span>
                  <span class="date"></span>
                </div>
                <div class="member-position">
                  <span class="position">{{ member1to1.position }}</span> /
                  <span class="department">{{ member1to1.department }}</span>
                </div>
                <div class="last-message"></div>
              </div>
            </div>
            <a 
              class="btn-menu leave" title="leave talking-on" 
            >
              <i class="mdi mdi-exit-to-app"></i>
            </a>
          </div>
          <div class="separator" />
          <div class="message-container">
            <div 
              v-for="message in messageList" 
              class="message-item-date"
              :key="message.index"
            >
              <div class="date"><label>{{ message.date }}</label></div>
              <div 
                v-for="item in message.messages" 
                class="message-item-time"
                :class="item.class"
                :key="item.index"
              >
                <div class="item-wrapper">
                  <div v-if="item.class == 'sender'" class="time-info-box">
                    <div class="top">
                      <!-- <span v-if="item.read == 'N'" class="unread" title="unread"><i class="mdi mdi-alert-box-outline"></i></span> -->
                      <!-- <a class="btn-del-message" title="delete message" @click="onDelMessage(item.idx)"><i class="mdi mdi-close"></i></a> -->
                    </div>
                    <div class="bottom">
                      {{ item.time }}
                    </div>
                  </div>
                  <div class="message" :class="item.class"><pre>{{ item.text }}</pre></div>
                  <div v-if="item.class == 'recipient'" class="time-info-box">
                    <div class="top">
                      <!-- <a class="btn-del-message" title="clear result" @click="onClearResult()"><i class="mdi mdi-close"></i></a>
                      <span class="unread"><i class="mdi mdi-alert-box-outline"></i></span> -->
                    </div>
                    <div class="bottom">
                      {{ item.time }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="mode == __C_.MESSENGER.MODE_GROUP_MESSAGE" class="tab-group-messages">
          <div class="tab-header">
            <span class="tab-title">Group Messages</span>
          </div>
          <div class="group-name-wrapper">
            <div class="header">
              <span><i class="mdi mdi-text-subject"></i></span>
            </div>
            <input 
              v-model="groupChatOn.chatName" 
              class="input-find-member" 
              type="text" 
              placeholder="[ Type title of the group message. ]"
            />
            <div class="footer" :class="{ talking_on: groupChatOn.chatNo > 0 }">
              <a v-if="groupChatOn.chatNo===0" class="btn-save-group-message" @click="groupChatOn.saved=!groupChatOn.saved">
                <i v-if="groupChatOn.saved" class="mdi mdi-account-multiple-minus" title="cancle to save"></i>
                <i v-else class="mdi mdi-account-multiple-plus" title="Save to group talking-on"></i>
              </a>
              <span v-else><i class="mdi mdi-chat" title="saved group talking-on"></i></span>
            </div>
          </div>
          <div class="messages-header">
            <div class="title">
              <span>{{ groupChatOn.members.length }} member{{ numSuffix(groupChatOn.members) }} in Group-Message.</span>
            </div>
            <div class="separator" />
            <div 
              class="member-names"
              :class="classExtend"
              @mouseover="classExtend='extend'"
              @mouseout="classExtend=''"
            >
              <div 
                v-for="member in groupChatOn.members" 
                class="name" 
                :key="member.index"
                :title="`${member.position} / ${member.department}`"
              >{{ member.name }}</div>
            </div>
          </div>
          <div class="separator" />
          <div v-if="!groupChatOn.chatNo" class="message-container">
            <div 
              v-for="message in messageList" 
              class="message-item-date"
              :key="message.index"
            >
              <div class="date"><label>{{ message.date }}</label></div>
              <div 
                v-for="item in message.messages" 
                class="message-item-time"
                :class="item.class"
                :key="item.index"
              >
                <div class="item-wrapper">
                  <div class="state" title="recipients / read">(<a class="recipients">{{ item.members }}</a>/{{ item.unread }})</div>
                  <div class="time">{{ item.time }}</div>
                  <div class="message" :class="item.class"><pre>{{ item.text }}</pre></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="message-container">
            <div 
              v-for="message in messageList" 
              class="message-item-date"
              :key="message.index"
            >
              <div class="date"><label>{{ message.date }}</label></div>
              <div 
                v-for="memberMessage in message.messages" 
                class="message-item-member"
                :class="memberMessage.class"
                :key="memberMessage.index"
              >
                <!-- 
                  recipient : you,
                  sender    : me,
                 -->
                <v-avatar v-if="memberMessage.class=='recipient'"><v-icon></v-icon></v-avatar>
                <div v-if="memberMessage.class=='recipient'" class="message-box">
                  <div class="member-name">
                    {{ `${memberMessage.member.name} / ${memberMessage.member.position} / ${memberMessage.member.department}` }}
                  </div>
                  <div 
                    v-for="message in memberMessage.messages" 
                    class="__message"
                    :key="message.index"
                  >
                    <div class="__text"><pre>{{ message.text }}</pre></div>
                    <div class="__time">{{ message.time }}</div>
                  </div>
                </div>

                <div v-if="memberMessage.class=='sender'">
                  <div 
                    v-for="message in memberMessage.messages" 
                    class="__message"
                    :key="message.index"
                  >
                    <div class="__time">{{ message.time }}</div>
                    <div class="__text"><pre>{{ message.text }}</pre></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-else-if="mode == __C_.MESSENGER.MODE_NOTICE" class="tab-notice">
          <div class="tab-header">
            <span class="tab-title">Notice</span>
          </div>
          <div class="tab-sub">
            <a v-ripple class="tab-received" :class="{ selected: !noticeOnSent }" title="received" @click="onNotice(false)">
              <i v-if="!noticeOnSent" class="mdi mdi-arrow-down-bold-box"></i>
              <i v-else class="mdi mdi-arrow-down-bold-box-outline"></i>
            </a>
            <a v-ripple class="tab-sent" :class="{ selected: noticeOnSent }" title="sent" @click="onNotice(true)">
              <i v-if="noticeOnSent" class="mdi mdi-arrow-up-bold-box"></i>
              <i v-else class="mdi mdi-arrow-up-bold-box-outline"></i>
            </a>
          </div>
          <div v-if="noticeOnSent" class="messages-header">
            <div class="title">
              <span>{{ memberSelected.length > 0 ? memberSelected.length : 'No' }} member{{ numSuffix(memberSelected) }} selected.</span>
            </div>
          </div>
          <div v-if="noticeOnSent" class="separator" />
          <div v-if="noticeOnSent" class="message-container sent">
            <div 
              v-for="message in messageList" 
              class="message-item-date"
              :key="message.index"
            >
              <div class="date"><label>{{ message.date }}</label></div>
              <div 
                v-for="item in message.messages" 
                class="message-item-time"
                :class="item.class"
                :key="item.index"
              >
                <div>
                  <div class="item-wrapper">
                    <div class="state" title="recipients / read">(<a class="recipients">{{ item.members }}</a>/{{ item.unread }})</div>
                    <div class="time">{{ item.time }}</div>
                    <div class="message" :class="item.class"><pre>{{ item.text }}</pre></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="message-container received">
            <div 
              v-for="message in messageList" 
              class="message-item-date"
              :key="message.index"
            >
              <div class="date"><label>{{ message.date }}</label></div>
              <div 
                v-for="memberMessage in message.messages" 
                class="message-item-member"
                :class="memberMessage.class"
                :key="memberMessage.index"
              >
                <!-- 
                  recipient : you,
                  sender    : me,
                 -->
                <v-avatar v-if="memberMessage.class=='recipient'"><v-icon></v-icon></v-avatar>
                <div v-if="memberMessage.class=='recipient'" class="message-box">
                  <div class="member-name">
                    {{ `${memberMessage.member.name} / ${memberMessage.member.position} / ${memberMessage.member.department}` }}
                  </div>
                  <div 
                    v-for="message in memberMessage.messages" 
                    class="__message"
                    :key="message.index"
                  >
                    <div class="__text"><pre>{{ message.text }}</pre></div>
                    <div class="__time">{{ message.time }}</div>
                  </div>
                </div>

                <div v-if="memberMessage.class=='sender'">
                  <div 
                    v-for="message in memberMessage.messages" 
                    class="__message"
                    :key="message.index"
                  >
                    <div class="__time">{{ message.time }}</div>
                    <div class="__text"><pre>{{ message.text }}</pre></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div v-if="ready2input" class="message-input-box">
          <textarea 
            v-model="inputMessage" 
            class="input-box" 
            @keyup.ctrl.enter="onSendMessage"
          />
          <div class="message-button-area">
            <span>&lt;Ctrl + Enter&gt; to send message.</span>
            <j-button
              class="type01 sky type_full"
              :class="{ disabled: disableSendingMessage }"
              :disabled="disableSendingMessage"
              @click="onSendMessage"
            >Send</j-button>
          </div>
        </div>
      </div>
    </div>
  </j-overlay>
</template>
<script>
import '@/assets/stylus/ui/component/_jCommMessenger.styl'

import * as d3 from 'd3'
// import Moment from 'moment'
import { mapState, mapActions } from "vuex"

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { NoticeService, SystemCodeService } from "@/services"
import Url from '@/services/api/request.url'
import JServiceComponents from '@/components/JServiceComponentTargets'

export default {
  name: 'j-modal-slide-component',
  mixins: [
    SafeIdMixin
  ],
  components: {
    ...JServiceComponents
  },
  props: {
    component: String,
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({})
    },
    value: null,
  },
  data: () => ({
    noticeService: null,
    systemCodeService: null,

    classExtend: 'extend',
    classWrapper: 'closed',
    doubleClicked: false,
    inputMember: '',
    inputMessage: '',
    memberSelectAll: false,
    member1to1: {},
    memberSelected: [],
    memberList: [],
    memberListUnread: [],
    menuSelected: __C.MESSENGER.MODE_FIND_MEMBER,
    messageList: [],
    messenger: null,
    modePrev: '',
    opened: false,
    subMenuOn: false,
    subMenuOptions: [
      { label: 'One-Way Group Messages', mode: __C.MESSENGER.MODE_NOTICE }
    ],

    noticeOn: {},
    noticeOnSent: false,
    groupChatOn: {},
    talkingONs: [],
    talkingONGroup: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.NOTICE, ['unreads']),

    __C_() { return __C },
    
    // just one time used for sending message to the group.
    chatName() {
      return this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE ? '' : (
        this.groupChatOn.chatNo===0 ? 
        (!this.groupChatOn.saved ? __C.MESSENGER.MESSAGE_NOT_SAVE : this.groupChatOn.chatName) :
        this.groupChatOn.chatName
      )
    },
    disableSendingMessage() { return (!this.memberSelected1to1 && this.memberSelected.length === 0) || !this.inputMessage },
    isGroupChatOn() { return Object.keys(this.groupChatOn).length > 0 },
    memberSelected1to1() { return Object.keys(this.member1to1).length > 0 },
    mode() { return this.menuSelected },
    ready2input() { 
      return (
        [ __C.MESSENGER.MODE_1TO1_MESSAGE, __C.MESSENGER.MODE_GROUP_MESSAGE ].includes(this.mode) ||
        (this.mode == __C.MESSENGER.MODE_NOTICE && this.memberSelected.length > 0)
      )
    },
    recipientId() {
      if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE) var id_ = this.member1to1.id
      else id_ = this.groupChatOn.members.map(m_ => m_.id).join(',')
      return id_
    },
    recipientSn() {
      if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE) var sn_ = this.member1to1.sn
      else sn_ = this.groupChatOn.members.map(m_ => m_.sn).join(',')
      return sn_
    },
    unreadMessages() { 
      return Object.keys(this.unreads).length > 0 ? this.unreads.group + this.unreads.one : 0 
    },
    unreadNotices() { 
      return Object.keys(this.unreads).length > 0 ? this.unreads.non : 0 
    }
  },
  watch: {
    value(val) {
      if(val) this.open()
      else this.onClose()
    }
  },
  created() {
    this.localId = this.id || 'j-messenger__' + this.safeId('')
    this.noticeService = new NoticeService()
    this.systemCodeService = new SystemCodeService()
  },
  mounted() {
    this.mount()

    window.addEventListener('keydown', this.onKeydown)
    d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onClose)

    this.systemCodeService.permissionGroup(res => {
      if (!res) res = []
      this.permitOptions = res
    })
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.NOTICE, ['getUnreadNoticeNum']),

    onOpen1To1Chat(member) {
      this.doubleClicked = true
      this.member1to1 = member
      this.onMenuSelect(__C.MESSENGER.MODE_1TO1_MESSAGE)

      setTimeout(() => { this.doubleClicked = false }, 350)
    },
    onClearResult(reqSelectedMember=false) {
      this.memberSelectAll = false
      this.memberList = []
      this.permitOptions.forEach(option => { option.selected = false })
      if(!reqSelectedMember && this.inputMember.includes('Selected:')) this.memberSelected = []
      this.inputMember = ''
    },
    onClearSelected() {
      if(this.inputMember.includes('Selected:')) {
        this.inputMember = ''
        this.memberList = []
      }
      this.memberSelected = []
      this.memberSelectAll = false
    },
    onClose() {
      d3.select(`#${this.localId}`).select('.content').transition().duration(150).style('opacity', 0)

      setTimeout(() => { 
        this.classWrapper = 'closed'
      }, 150)
      setTimeout(() => { 
        this.opened = false
        this.$emit('input', false) 
      }, 450)
    },
    onDelMessage(idx) {
      // 
    },
    onFindMembers() {
      if(this.inputMember == '') {
        this.onClearResult()
        return
      } else {
        this.memberSelectAll = false
        // this.permitOptions.forEach(option => { option.selected = false })
      }

      this.findMembers(this.inputMember)
    },
    onGotoGroupMessage(group=null) {
      // Comes from the MODE_FIND_MEMBER
      if(this.mode == __C.MESSENGER.MODE_FIND_MEMBER) {
        let members_ = this.memberSelected.length - 1
        this.groupChatOn = {
          chatNo: 0,
          chatName: `${this.memberSelected[0].name} + ${members_} member${this.numSuffix(this.memberSelected)}`,
          members: JSON.parse(JSON.stringify(this.memberSelected)),
          saved: false
        }
        this.onMenuSelect(__C.MESSENGER.MODE_GROUP_MESSAGE)
        return
      }
      
      // Comes from the MODE_TALKING_ON
      this.groupChatOn = group
      this.noticeService.getChatMembers(this.groupChatOn.chatNo, this.account.id).then(res => {
        this.groupChatOn.members = res || []
        this.onMenuSelect(__C.MESSENGER.MODE_GROUP_MESSAGE)
      })
    },
    onKeydown(e) {
      if(e.key == 'Escape') this.onClose()
    },
    onMemberSelect(member) {
      setTimeout(() => {
        if(this.doubleClicked) return

        let idx = this.memberSelected.findIndex(m_ => m_.sn == member.sn)
        if(idx >= 0) {
          this.memberSelected[idx] = null
          this.memberSelected = this.memberSelected.filter(m_ => !!m_)

          if(this.memberSelected.length === 0) this.memberSelectAll = false
          return 
        }
        this.memberSelected.push(member)
      }, 350)
    },
    onMenuSelect(mode_) {
      if(mode_ == __C.MESSENGER.MODE_GROUP_MESSAGE && this.groupChatOn.members.length === 0) return
      // if previous mode was 'Unread', get1To1Messages should 
      // found a member from memberListUnread, not memberList.
      this.modePrev = this.mode
      this.menuSelected = mode_

      if(mode_ == __C.MESSENGER.MODE_NOTICE) this.getNotices()
      else if(mode_ == __C.MESSENGER.MODE_TALKING_ON) this.getTalkingONs()
      else if(mode_ == __C.MESSENGER.MODE_1TO1_MESSAGE) this.get1To1Messages()
      else if(mode_ == __C.MESSENGER.MODE_GROUP_MESSAGE) this.getGroupMessages()
    },
    // from the websocket
    onMessage(message) {
      if(!this.opened) {
        this.getUnreadNoticeNum(this.account.id)
        return
      }

      let message___ = JSON.parse(message.data)

      if(this.mode == __C.MESSENGER.MODE_TALKING_ON) this.getTalkingONs()
      else if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE && this.member1to1.chatNo == message___.chatNo) this.get1To1Messages()
      else if(this.mode == __C.MESSENGER.MODE_GROUP_MESSAGE && this.groupChatOn.chatNo == message___.chatNo) this.getGroupMessages()
      else this.getUnreadNoticeNum(this.account.id)
    },
    onNotice(sent) {
      this.noticeOnSent = sent
      this.getNotices()
    },
    open() {
      this.opened = true
      setTimeout(() => { 
        d3.select(`#${this.localId}`).select('.content').transition().delay(250).duration(250).style('opacity', 1)
        this.classWrapper = 'opened' 
      })
    },
    onSelectAllMembers(v) {
      if(v) this.memberList.forEach(m_ => {
        if(this.memberSelected.findIndex(m___ => m___.sn == m_.sn) >= 0) return
        this.memberSelected.push(m_)

      }); else this.memberList.forEach(m_ => {
        let idx__ = this.memberSelected.findIndex(m___ => m___.sn == m_.sn)
        if(idx__ < 0) return

        this.memberSelected[idx__] = null
        this.memberSelected = this.memberSelected.filter(m___ => !!m___)
      })
    },
    onSelectedMembers() {
      this.onClearResult(true)
      this.findMembers(this.recipientSn)
      this.inputMember = `Selected: ${this.memberSelected.length} member(s)`
    },

    onSendMessage() {
      if(!this.inputMessage) return

      let chatNo_ = this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE ? this.member1to1.chatNo : this.groupChatOn.chatNo

      let params = new URLSearchParams()
      params.append('text', this.inputMessage)
      params.append('recipients', this.recipientSn)
      params.append('sender', this.account.id)
      params.append('chatNo', chatNo_)
      params.append('chatName', this.chatName)

      this.noticeService.putMessage(params).then((res) => {
        if(!chatNo_) {
          if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE) {
            let index_ = this.memberList.findIndex(m_ => m_.sn == this.member1to1.sn)
            // Data-Type must be converted to Number for the Go Struct type int
            this.memberList[index_].chatNo = Number(res.chatNo)
            this.member1to1.chatNo = Number(res.chatNo)

          } else this.groupChatOn.chatNo = Number(res.chatNo)
        }

        this.sendMessage(
          __C.MESSENGER.COMMAND_SEND_MESSAGE,
          this.recipientId,
          this.inputMessage
        )
        this.inputMessage = ''

        if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE) this.get1To1Messages()
        else if(this.mode == __C.MESSENGER.MODE_GROUP_MESSAGE) this.getGroupMessages()
      })
    },
    onSubMenuClicked(mode) {
      this.subMenuOn = false
      this.onMenuSelect(mode)
    },
    onTalkingOn(isGroup=false) {
      this.talkingONGroup = isGroup
      this.onMenuSelect(__C.MESSENGER.MODE_TALKING_ON)
    },

    classMemberSelected(sn) { return this.memberSelected.findIndex(m_ => m_.sn == sn) >= 0 ? 'selected' : '' },
    classMenuSelected(mode_) { return this.menuSelected === mode_ ? 'selected' : '' },

    findMembers(name) {
      this.noticeService.findMessageMembers(this.account.id, name).then(res => {
        this.memberList = res || []
      })
    },
    findMembersUnread() {
      this.noticeService.findMembersUnread(this.account.id).then(res => {
        this.memberListUnread = res || []
      })
    },
    getNotices() {
      this.noticeService.getNotices(this.account.id, this.noticeOnSent ? 'sent' : 'received').then(res => {
        this.messageList = this.messageTransform(res || [])
        if(!this.noticeOnSent) this.getUnreadNoticeNum(this.account.id)
      })
    },
    get1To1Messages() {
      this.noticeService.get1To1Messages(this.member1to1.chatNo, this.account.id).then(res => {
        this.messageList = this.messageTransform(res || [])
        this.getUnreadNoticeNum(this.account.id)
        this.scrollToBottom()
      })
    },
    getGroupMessages() {
      this.noticeService.getGroupMessages(this.groupChatOn.chatNo, this.account.id).then(res => {
        this.messageList = this.messageTransform(res || [])
        this.scrollToBottom()
        this.getUnreadNoticeNum(this.account.id)
      })
    },
    getTalkingONs() {
      let chatType = this.talkingONGroup ? __C.MESSENGER.CHAT_TYPE_GROUP : __C.MESSENGER.CHAT_TYPE_1TO1
      this.noticeService.getTalkingONs(this.account.id, chatType).then(res => {
        this.talkingONs = res || []
        this.getUnreadNoticeNum(this.account.id)
      })
    },

    messageTransform(messages) {
      if(messages.length === 0) return messages

      let messageList_ = []
      let messageDateList_ = []
      let messageMemberList_ = []
      let prevDate_ = messages[0].date
      let prevMember_ = messages[0].senderId
      // for the notice menber
      let prevMemberData_ = messages[0]

      messages.forEach((m_, i) => {
        let f_dateChanged___ = () => {
          messageList_.push({
            date: prevDate_,
            messages: messageDateList_
          })
          messageDateList_ = []
          prevDate_ = m_.date
        }

        if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE) {
          if(prevDate_ != m_.date) f_dateChanged___()
          
          messageDateList_.push({
            idx: m_.idx,
            class: m_.senderId == this.account.id ? 'sender' : 'recipient',
            time: m_.time,
            text: m_.message,
            members: m_.members,  // member count
            read: m_.read,
          })
        } else if(this.mode == __C.MESSENGER.MODE_GROUP_MESSAGE && this.groupChatOn.chatNo) {
          let f_memberChanged___ = () => {
            messageDateList_.push({
              class: prevMember_ == this.account.id ? 'sender' : 'recipient',
              member: this.groupChatOn.members.find(m_ => m_.id == prevMember_),
              messages: messageMemberList_
            })
            messageMemberList_ = []
            prevMember_ = m_.senderId
          }

          if(prevDate_ != m_.date) {
            if(prevMember_ == m_.senderId) f_memberChanged___()
            f_dateChanged___()
          }
          if(prevMember_ != m_.senderId) f_memberChanged___()

          messageMemberList_.push({
            idx: m_.idx,
            time: m_.time,
            text: m_.message,
            read: m_.read,
          })

          if(messages.length - 1 === i) f_memberChanged___()

        } else if(this.mode == __C.MESSENGER.MODE_NOTICE && !this.noticeOnSent) {
          let f_memberChanged___ = () => {
            messageDateList_.push({
              class: prevMember_ == this.account.id ? 'sender' : 'recipient',
              member: {
                sn: prevMemberData_.sn,
                id: prevMemberData_.senderId,
                name: prevMemberData_.name,
                position: prevMemberData_.position,
                department: prevMemberData_.department,
              },
              messages: messageMemberList_
            })
            messageMemberList_ = []
            prevMember_ = m_.senderId
            prevMemberData_ = m_
          }

          if(prevDate_ != m_.date) {
            if(prevMember_ == m_.senderId) f_memberChanged___()
            f_dateChanged___()
          }
          if(prevMember_ != m_.senderId) f_memberChanged___()

          messageMemberList_.push({
            idx: m_.idx,
            time: m_.time,
            text: m_.message,
            read: m_.read,
          })

          if(messages.length - 1 === i) f_memberChanged___()
          
        } else {
          messageDateList_.push({
            idx: m_.idx,
            class: 'sender',
            time: m_.time,
            text: m_.message,
            members: m_.members,  // member count
            unread: m_.unread,
          })
        }

        // At the last of the list, put accumulated messages 
        // into the current date message container.
        if(messages.length - 1 === i) {
          messageList_.push({
            date: prevDate_,
            messages: messageDateList_
          })
        }
      })
      return messageList_
    },

    // ### Initialize (open) messenger intance ---------------------
    mount() {
      this.messenger = new WebSocket(Url.websocket.messenger)
      this.messenger.onopen = (event) => {
        this.getUnreadNoticeNum(this.account.id)
        this.sendMessage(__C.MESSENGER.COMMAND_OPEN_CONNECTION)
        // console.log("Socket onOpen Connection: ", event);
      }
      this.messenger.onclose = event => {
        // console.log("Socket Closed Connection: ", event);
        // socket.send("Client Closed!")
      }
      this.messenger.onmessage = this.onMessage

      this.messenger.onmessage = (event) => {
        console.debug("WebSocket message received:", event);
      };

      this.messenger.onerror = error => {
        console.error('[USER #Socket Error]', error)
      }
    },
    // ---------------------------- ---------------------------------

    numSuffix(target) { return target.length > 1 ? 's' : '' },
    sendMessage(command, recipient='', message='') {
      let data_ = JSON.stringify({
        command: command,
        data: {
          chatNo: this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE ? this.member1to1.chatNo : this.groupChatOn.chatNo,
          senderId: this.account.id,
          recipientId: recipient,
          message: message,
        }
      })
      this.messenger.send(data_)
    },
    scrollToBottom() {
      if(this.mode == __C.MESSENGER.MODE_NOTICE) var scrollParent_ = 'tab-notice'
      else if(this.mode == __C.MESSENGER.MODE_1TO1_MESSAGE) scrollParent_ = 'tab-1to1-messages'
      else scrollParent_ = 'tab-group-messages'

      setTimeout(() => {
        let scrollEl_ = document.querySelector(`.${scrollParent_} .message-container`)
        scrollEl_.scrollTo({top: scrollEl_.scrollHeight - scrollEl_.offsetHeight, behavior: 'smooth'})
      })
    },
    truncateMessage(message) {
      if(message.length > 150) return `${message.substring(1, 100)}...`
      return message
    }
  }
}

// TO DO
// 1. realtime message transfer to members & receiving message from sender.
// 2. unread message count for each part of the section
// 3. one-way group messages, not reply

</script>
