<template>
    <v-list v-if="menuItems.length > 0" class="lnb_list">
      <v-list-tile
        v-for="item in menuItems"
        :key="item.title"
        :class="{ selected: selected(item.url, $route.path) }"
      >
        <i :class="[item.iconClass]"></i>
        <v-list-tile-content>
          <v-list-tile-title @click="onAction(item)">{{ item.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import __C from '@/primitives/_constant_'

export default {
  name: 'j-lnb-router-linked-menu',
  data: () => ({
    menuItems: [],
  }),
  watch: {
    '$route.path'(val) {
      this.setMenuItems()
    },
  },
  created() {
    this.setMenuItems()
  },
  methods: {
    ...mapMutations(['setFbSharedName']),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, ['setFilemanagerOpened']),

    onAction(item) {

      if(item.type == 'func') {
        item.callFunc()
      } else {
        this.route(item.url)
      }
    },

    route(path) {
      if(this.$route.path == path) return
      this.$router.push(path)
    },
    selected(path, matching, exact = false) {
      if(!path || !matching) return false
      if(exact) {
        if (path == matching) return true
        return false
      } else {
        if (matching.search(path) > -1) return true
        return false
      }
    },
    setMenuItems() {
      if([
        '/service/system_completion/regulatory/certificate_of_inspection/skyline',
        '/service/system_completion/regulatory/certificate_of_inspection/shared_folder'
        ].includes(this.$route.path)
      ) this.menuItems = [
        { 
          type: 'func',   // func | url
          title: 'Shared Folder', 
          url: '/service/system_completion/regulatory/certificate_of_inspection/shared_folder',
          callFunc: () => {
            this.setFbSharedName(__C.FILE_BROWSER.ROOT_COI)
            this.setFilemanagerOpened(true)
          },
          iconClass: ''
        }
      ]; else this.menuItems = []
    }
  }
}
</script>
