import __C from '../primitives/_constant_'
import Url from '@/services/api/request.url'

export default {
  data: () => ({
    iframe: null,
  }),
  created() {
    this.iframe = document.getElementById('__iframe__')
  },
  methods: {
    downloadfile(id, token) {
      if(!id || !Url.download) {
        console.warn(`[ID: ${id}] File is unavailable to download.`)
        return false
      }
      let params = new URLSearchParams()
      params.append('token', token)
      this.iframe.src = `${Url.download}/${id}?${params.toString()}`
      return true
    }
  }
}