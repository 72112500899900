export default {
  items: [
    {
      title: 'Account Manager',
      url: '/admin/acctmanager',
      iconClass: 'jcon_account'
    },
    {  
      title: 'Access History OverView',
      url: '/admin/accesshist_OverView',
      iconClass: 'jcon_code'
    },                 
  ]
}
