
<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="j_file_manager"
    style="z-index: 6;"
    :id="localId"
    :opacity="0"
  >
    <div class="file_action_contents" :class="classWrapper">

      <f-header></f-header>

      <div class="file_action_wrapper">
        <main-action />
        <files class="files" />
      </div>

      <prompts></prompts>

      <j-modal-slide-for-service v-model="searchOpen">
        <template>
          <div>
            <search v-if="isSearchOpened" />
          </div>
        </template>
      </j-modal-slide-for-service>

    </div>
  </j-overlay>
</template>
<script>
import '@/assets/stylus/ui/component/_jFileManager.styl'

import * as d3 from 'd3'
import { mapState, mapMutations } from 'vuex'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import FHeader from '@/containers/layout/JFtb'
import Files from '../../lib/filebrowser/src/views/Files'
import Search from '../../lib/filebrowser/src/components/Search'
import MainAction from '../../lib/filebrowser/src/components/Sidebar'
import Prompts from '../../lib/filebrowser/src/components/prompts/Prompts'

export default {
  name: 'j-file-manager',
  mixins: [
    SafeIdMixin
  ],
  components: {
    FHeader,
    Files,
    MainAction,
    Prompts,
    Search
  },
  props: {
    value: null,
  },
  data: () => ({
    classWrapper: 'closed',
    opened: false,
    searchOpened: false,
  }),
  computed: {
    ...mapState(['fbname', 'show']),
    searchOpen: {
      get() { return this.searchOpened },
      set(val) { this.searchOpened = val }
    },
    isSearchOpened() { return this.show == 'search' }
  },
  watch: {
    searchOpened(val) {
      if(val) this.setModalEnv()
    },
    value(val) {
      if(val) this.onOpen()
      else this.onClose()
    }
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onClose)
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapMutations(['initFilebrowser', 'setFbSharedName']),

    onKeydown(e) {
      if(e.key == 'Escape') this.onClose()
    },
    onOpen() {

      let fbname_ = this.fbname
      this.initFilebrowser()
      this.setFbSharedName(fbname_)

      this.opened = true
      setTimeout(() => { 
        this.classWrapper = 'opened' 
        d3.select(`#${this.localId}`).select('.files').transition().delay(300).duration(200).style('opacity', 1)
      })
    },
    onClose() {
      d3.select(`#${this.localId}`).select('.files').transition().duration(150).style('opacity', 0)

      setTimeout(() => { 
        this.classWrapper = 'closed'
      }, 150)
      setTimeout(() => { 
        this.opened = false
        this.$emit('input', false) 
      }, 450)
    },
  }
}
</script>
