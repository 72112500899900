var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentMenu)?_c('div',{staticClass:"anb"},[_c('div',{staticClass:"anb_menu"},[_c('v-icon',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(" mdi-menu ")]),_c('ul',_vm._l((_vm.currentMenu.main.children),function(item){return _c('li',{key:item.name},[_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple"}],class:{ selected: item.path == _vm.$route.path },attrs:{"to":item.path}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),0)],1),(_vm.hasChildSub)?_c('div',{staticClass:"anb_submenu"},[(_vm.hasChildThird)?_c('div',{staticClass:"layer"},_vm._l((_vm.menuTree),function(phase,i){return _c('v-menu',{key:phase.phaseCode,attrs:{"offset-y":"","open-on-hover":""}},[_c('a',{staticClass:"sub-menu-activator",class:{ selected: (!['ALL'].includes(phase.phaseCode) && phase.phaseCode == _vm.codePropagated.code) || (!!_vm.subSelectedCode && phase.phaseCode == _vm.subSelectedCode.code) },attrs:{"slot":"activator"},on:{"click":function($event){return _vm.setCode(_vm.currentMenu.sub[i])}},slot:"activator"},[_vm._v(" "+_vm._s(phase.phaseName)+" ")]),(phase.children.length > 0)?_c('div',{staticClass:"category-menu-item-container"},[_c('div',{staticClass:"menu-item-routes"},_vm._l((phase.children),function(group){return _c('div',{key:group.groupCode,staticClass:"menu-item-group",attrs:{"offset-y":"","open-on-hover":""}},[(_vm.subItemEnabled)?_c('span',{staticClass:"group-menu",class:{ selected: group.groupCode == _vm.codePropagated.groupCode },attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" "+_vm._s(group.groupName)+" ")]):_c('a',{staticClass:"group-menu",class:{ selected: group.groupCode == _vm.codePropagated.code },attrs:{"slot":"activator"},on:{"click":function($event){return _vm.setCode({ 
                  name: group.groupName, 
                  code: group.groupCode, 
                  parentName: phase.phaseName, 
                  parentCode: phase.phaseCode 
                }, true, i)}},slot:"activator"},[_vm._v(" "+_vm._s(group.groupName)+" "),(_vm.$route.path.search('/sysenv/config/nav') > -1 && Number(group.numChild))?_c('span',{staticClass:"group-num"},[_vm._v("("+_vm._s(group.numChild)+")")]):_vm._e()]),(_vm.subItemEnabled)?_c('div',{staticClass:"menu-item-item",attrs:{"offset-y":"","open-on-hover":""}},_vm._l((group.children),function(item){return _c('a',{key:item.itemCode,staticClass:"item-menu",class:{ selected: item.itemCode == _vm.codePropagated.code },attrs:{"slot":"activator"},on:{"click":function($event){return _vm.setCode({ 
                    name: item.itemName,
                    code: item.itemCode,
                    groupName: group.groupName,  
                    groupCode: group.groupCode,
                    parentName: phase.phaseName, 
                    parentCode: phase.phaseCode 
                  }, true, i)}},slot:"activator"},[_vm._v(" "+_vm._s(item.itemName)+" "),(Number(item.numChild))?_c('span',{staticClass:"item-num"},[_vm._v("("+_vm._s(item.numChild)+")")]):_vm._e(),(!!item.navIconPath)?_c('img',{staticClass:"router-img",attrs:{"height":"14px","src":item.navIconPath}}):_vm._e()])}),0):_vm._e()])}),0)]):_vm._e()])}),1):_c('div',{staticClass:"layer"},_vm._l((_vm.currentMenu.sub),function(item){return _c('v-menu',{key:item.name,attrs:{"offset-y":"","open-on-hover":""}},[_c('a',{staticClass:"sub-menu-activator",class:Object.assign({}, {selected: item.code == _vm.codePropagated.code || (!!_vm.subSelectedCode && item.code == _vm.subSelectedCode.code)}, _vm.styleStatus(item.itemStatus)),attrs:{"slot":"activator"},on:{"click":function($event){return _vm.setCode(item)}},slot:"activator"},[_vm._v(" "+_vm._s(item.name)+" ")])])}),1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }