<template>
  <div v-if="currentMenu" class="anb">
    <div class="anb_menu">
      <v-icon v-ripple>
        mdi-menu
      </v-icon>
      <ul>
        <li v-for="item in currentMenu.main.children" :key="item.name">
          <router-link :to="item.path" :class="{ selected: item.path == $route.path }" v-ripple>
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="hasChildSub" class="anb_submenu">
      <div v-if="hasChildThird" class="layer">
        <v-menu v-for="(phase, i) in menuTree" offset-y open-on-hover :key="phase.phaseCode">
          <a 
            class="sub-menu-activator" 
            slot="activator" 
            :class="{ selected: (!['ALL'].includes(phase.phaseCode) && phase.phaseCode == codePropagated.code) || (!!subSelectedCode && phase.phaseCode == subSelectedCode.code) }" 
            @click="setCode(currentMenu.sub[i])"
          >
            {{ phase.phaseName }}
          </a>
          <div v-if="phase.children.length > 0" class="category-menu-item-container">
            <div class="menu-item-routes">
              <div v-for="group in phase.children" offset-y open-on-hover :key="group.groupCode" class="menu-item-group">
                <span v-if="subItemEnabled" slot="activator" class="group-menu" :class="{ selected: group.groupCode == codePropagated.groupCode }">
                  {{ group.groupName }}
                </span>
                <a v-else slot="activator" class="group-menu" :class="{ selected: group.groupCode == codePropagated.code }" @click="setCode({ 
                    name: group.groupName, 
                    code: group.groupCode, 
                    parentName: phase.phaseName, 
                    parentCode: phase.phaseCode 
                  }, true, i)">
                  {{ group.groupName }}
                  <span v-if="$route.path.search('/sysenv/config/nav') > -1 && Number(group.numChild)" class="group-num">({{ group.numChild }})</span>
                </a>
                <div v-if="subItemEnabled" offset-y open-on-hover class="menu-item-item">
                  <a 
                    v-for="item in group.children" 
                    class="item-menu" 
                    slot="activator"
                    :class="{ selected: item.itemCode == codePropagated.code }"
                    :key="item.itemCode" 
                    @click="setCode({ 
                      name: item.itemName,
                      code: item.itemCode,
                      groupName: group.groupName,  
                      groupCode: group.groupCode,
                      parentName: phase.phaseName, 
                      parentCode: phase.phaseCode 
                    }, true, i)"
                  >
                    {{ item.itemName }}
                    <span v-if="Number(item.numChild)" class="item-num">({{ item.numChild }})</span>
                    <img v-if="!!item.navIconPath" class="router-img" height="14px" :src="item.navIconPath"> 
                  </a>
                </div>
              </div>
            </div>   
          </div>
        </v-menu>
      </div>
      <div v-else class="layer">
        <v-menu v-for="item in currentMenu.sub" offset-y open-on-hover :key="item.name">
          <a 
            class="sub-menu-activator" 
            slot="activator" 
            :class="{ selected: item.code == codePropagated.code || (!!subSelectedCode && item.code == subSelectedCode.code), ...styleStatus(item.itemStatus) }" 
            @click="setCode(item)"
          >
            {{ item.name }}
          </a>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>

import { ApplicationService, SystemService } from '@/services'
import { CategoryMenuMixin } from '@/mixins/category.menu.mixin'

export default {
  name: 'j-anb',
  mixins: [CategoryMenuMixin],
  data: () => ({
    applicationService: null,
    systemService: null,
    menuItems: [],
    menuTree: [],
    currentMenu: {
      main: {},
      sub: [],
      third: [],
      fourth: [],
    },
    hasChildSub: true,  // default true, contrary to hasChildThird
    hasChildThird: false,
    subSelectedCode: null,
    drawNavMenu: Function,
    subItemEnabled: false,
    snackbar: false,
    toGoCode: {},
  }),
  created () {
    this.applicationService = new ApplicationService()
    this.systemService = new SystemService()
    this.setMenu(this.$route.path)
  },
  mounted() {
    // 
  },
  watch: {
    menuUpdated(val) {
      this.drawNavMenu(true)
    },
    routerPath(val) {
      this.setMenu(val)
    }
  },
  computed: {
    routerPath () {
      return this.$route.path
    },
    codePropagated: {
      get () { return this.$store.state.sysenv.codePropagated },
      set (value) { this.$store.commit('sysenv/codePropagate', value) }
    },
    menuUpdated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    },
  },
  methods: {
    setMenu(path) {
      this.drawNavMenu = () => {}
      
      let menuRequested = this.menuItems.find(item => path.search(item.pathPrefix) > -1)
      if(menuRequested) this.currentMenu = {
        main: {},
        sub: [],
        third: [],
        fourth: [],
      }; else {
        this.currentMenu = null
        return
      }

      if (!!this.currentMenu.main && this.currentMenu.main.pathPrefix != menuRequested.pathPrefix) {
        this.currentMenu.main = menuRequested
      } 
      
      this.subItemEnabled = false
      this.subSelectedCode = null
      // this.currentMenu.sub = []
      // this.currentMenu.third = []
      this.hasChildSub = true // default true, contrary to hasChildThird
      this.hasChildThird = false
      this.currentMenu.main.children.find(c => c.path == path).getChild(this.$route.path)
    },
    setCode(code, thirdProc = false, parentIndex = 0) {
      if(!thirdProc) this.subSelectedCode = null
      else {
        this.subSelectedCode = this.currentMenu.sub[parentIndex]
      }

      if(!!code.hasChild || !!code.pretend) {
        this.currentMenu.third = []
        this.subSelectedCode = code
        code.getChild()
        this.snackbar = false
        this.toGoCode = code
        setTimeout(() => {
          this.currentMenu.third.length == 0 ? this.snackbar = true : false
        }, 100);
      } else this.codePropagated = code
    },
    styleStatus(status) {
      if(status == 'PLANNED') return { planned: true }
      else if(status == 'UNDER') return { underconst: true }

      return {}
    },
  }
}
</script>
