<template>
  <div class="j_comm_notification">
    <span class="notice_text">※ NOTICE : </span>
    <span class="notice_value">{{notification}}</span>   
  </div>
</template>

<script>
import { SystemCodeService }from "@/services"
import '@/assets/stylus/ui/component/_jCommNotification.styl'

export default {
  data: () => ({
    items: [],
    systemCodeService: null,
    notification: '',
  }),
  created() {
    this.systemCodeService = new SystemCodeService()
  },
  mounted() {
    this.systemCodeService.getNotification(res => {
      if (res && res.length > 0) {
        this.items = res
        this.notification = this.items[0].title ? this.items[0].title : ''
      } else {
         this.items = []
      }
    })
  }
}
</script>

<style>

</style>